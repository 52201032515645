import { httpService } from "./http.service";
import { GetOffersByParamsRequestBody, Offer } from "../types";

const apiUrl = process.env.REACT_APP_API_URL;

const getAllOffers = async () => {
  const apiResponse = await httpService.get<Offer[]>(`https://getalloffers${apiUrl}`);
  return apiResponse.data;
};
const getOffersByParams = async (params: GetOffersByParamsRequestBody) => {
  const apiResponse = await httpService.post<Offer[]>(`https://getoffersbyparams${apiUrl}`, params);
  return apiResponse.data;
};

const getOfferById = async (id: string) => {
  const apiResponse = await httpService.get<Offer>(`https://getoffer${apiUrl}?id=${id}`);
  return apiResponse.data;
};

const createOffer = async (body: Partial<Offer>) => {
  const apiResponse = await httpService.post<Offer>(`https://createoffer${apiUrl}`, body);
  return apiResponse.data;
};

export const offerService = {
  createOffer,
  getAllOffers,
  getOfferById,
  getOffersByParams,
};
