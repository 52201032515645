import React, { PropsWithChildren } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { OfferSummaryProps } from "../../../types";
import formatNumber, { getCurrentDate } from "../helpers";
import { createStyles } from "../../../../../styles/utils";
import { blueBorder } from "../../../../../styles/theme/colors";

const useStyles = createStyles({
  card: {
    border: `1px solid ${blueBorder}`,
    padding: 5,
    borderRadius: "3px",
    marginTop: 3,
  },
});

export const OfferSummary = ({ formValues, isBid }: OfferSummaryProps) => {
  const styles = useStyles();

  const {
    shareAmountFrom,
    shareAmountTo,
    requestedSharePrice,
    companyName,
    structure,
    shareClass,

    requestedValuation,
    offerAmountToSellTo,
    offerAmountToSellFrom,
  } = formValues;
  const date = getCurrentDate();
  let shareAmount = "";
  let size = "";

  if (shareAmountFrom && shareAmountTo) {
    const isShareAmountRange = shareAmountFrom !== shareAmountTo;

    if (isShareAmountRange) {
      shareAmount = `${formatNumber(shareAmountFrom)} - ${formatNumber(shareAmountTo)}`;
    } else {
      shareAmount = `${formatNumber(shareAmountFrom)}`;
    }
  }

  if (offerAmountToSellTo && offerAmountToSellFrom) {
    const isOfferAmountRange = offerAmountToSellTo !== offerAmountToSellFrom;

    if (isOfferAmountRange) {
      size = `$${formatNumber(Number(offerAmountToSellFrom))} - $${formatNumber(
        Number(offerAmountToSellTo)
      )}`;
    } else {
      size = `$${formatNumber(Number(offerAmountToSellFrom))}`;
    }
  }

  return (
    <Box>
      <Typography variant="body6" color="text.secondary">
        Indicative {isBid ? "bids" : "asks"} placed on Simetria are indications of sell interest and
        do not represent immediately executable trades. By placing an indicative bid, other market
        participants will be able to request an introduction to connect with you and discuss the
        indication.
      </Typography>
      <Box sx={styles.card}>
        <Typography variant="subtitle4">Order details</Typography>
        <Stack
          direction="row"
          spacing={3}
          pt={3}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <SummaryColumn>
            <SummaryRow label="Company" value={companyName} />
            <SummaryRow label="Structure" value={structure} />
            <SummaryRow
              label="Requested Valuation"
              value={requestedValuation ? `$${formatNumber(requestedValuation)}` : ""}
            />
            <SummaryRow label="Share Class" value={shareClass} />
          </SummaryColumn>
          <SummaryColumn>
            <SummaryRow label="Size" value={size} />
            <SummaryRow
              label="PPS"
              value={requestedSharePrice ? "$" + requestedSharePrice?.toLocaleString() : ""}
            />
            <SummaryRow label="Share Amount" value={shareAmount} />
            <SummaryRow label="Date" value={date} />
          </SummaryColumn>
        </Stack>
      </Box>
    </Box>
  );
};

const SummaryRow = (props: { label: string; value: React.ReactNode }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="body6" color="text.secondary">
        {props.label}:
      </Typography>
      <Typography variant="body5">{props.value}</Typography>
    </Stack>
  );
};

const SummaryColumn = ({ children }: PropsWithChildren) => {
  return (
    <Stack spacing={3} flex={1}>
      {children}
    </Stack>
  );
};
