import React from "react";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import { createStyles } from "../../styles/utils";
import { green10, green60, red20, red60 } from "../../styles/theme/colors";

type TagColor = "red" | "green";

interface TagProps {
  color: TagColor;
  children: React.ReactNode;
}

const useStyles = createStyles((_, props) => ({
  tag: {
    padding: "4px 8px",
    borderRadius: "3px",
    ...(props?.color === "red" && {
      color: red60,
      backgroundColor: red20,
    }),
    ...(props?.color === "green" && {
      color: green60,
      backgroundColor: green10,
    }),
  },
}));

export const Tag = ({ color, children }: TagProps) => {
  const styles = useStyles({ color });

  return (
    <Box component="span" sx={styles.tag}>
      <Typography variant="body6">{children}</Typography>
    </Box>
  );
};
