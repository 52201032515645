import { useEffect } from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { AppWrapper } from "./components/AppWrapper/AppWrapper";
import { SignInPage } from "./pages/SignIn/SignIn";
import { SettingService, emailService } from "./services";
import { createStyles } from "./styles/utils";
import { useAuth } from "@simetria/common/src/hooks";
import { useAuthenticateUser } from "./hooks/use-authenticate-user";
import { WaitingForApproval } from "./pages/WaitingForApproval/WaitingForApproval";
import { useSettings } from "./hooks/use-settings";

const useStyles = createStyles({
  loaderWrapper: {
    position: "absolute",
    inset: 0,
    display: "grid",
    placeItems: "center",
  },
});

function App() {
  const styles = useStyles();
  const { isAuth, isLoading: isAuthLoading, oauth0User } = useAuth();
  const { canAccessApp, isUserAccessLoading } = useAuthenticateUser();
  const { setSettings } = useSettings();

  useEffect(() => {
    const init = async () => {
      emailService.initialize();
      const settings = await SettingService.getSettings();
      setSettings(settings);
    };

    init();
  }, [setSettings]);
  
  // login
  if (!isAuth) {
    return <SignInPage />;
  }

  if (isUserAccessLoading || isAuthLoading) {
    return (
      <Box sx={styles.loaderWrapper}>
        <CircularProgress />
      </Box>
    );
  }

  // email verification
  if (isAuth && !oauth0User?.email_verified) {
  }

  // user waiting for approval page
  if (isAuth && !canAccessApp) {
    return <WaitingForApproval />;
  }

  return <AppWrapper />;
}

export default App;
