import { httpService } from "./http.service";
import { GetUserByAuthIdRequestBody, User } from "../types";

const apiUrl = process.env.REACT_APP_API_URL;

const getUserByAuthId = async (body: GetUserByAuthIdRequestBody) => {
  const apiResponse = await httpService.post<User>(`https://getuserbyauthid${apiUrl}`, body);
  return apiResponse.data;
};

const createUser = async (body: Partial<User>) => {
  const apiResponse = await httpService.post<User>(`https://createuser${apiUrl}`, body);
  return apiResponse.data;
};

export const userService = {
  createUser,
  getUserByAuthId,
};
