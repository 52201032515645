import { useEffect } from "react";

const event = new CustomEvent("closeIframeEvent", {});

export const AutoCloseIframe = (): JSX.Element => {
  useEffect(() => {
    window.parent.document.dispatchEvent(event);
  }, []);

  return <></>;
};
