import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { emailService } from "../../../services";
import { useDialog, useUser } from "../../../hooks";
import { ConnectDialog } from "../../ConnectDialog/ConnectDialog";
import { useSettings } from "../../../hooks/use-settings";
export const ActionsCellRenderer = (params: GridRenderCellParams) => {
  const { user } = useUser();
  const {
    settings: { contactEmail },
  } = useSettings();
  const [isConnectDialogOpen, openConnectDialog, closeConnectDialog] = useDialog();

  const onConnectClicked = () => {
    const { asset, offer } = params.row;
    const { firstName, lastName, workEmail } = user;
    const senderFullName = `${firstName} ${lastName}`;
    const connectMessage = `Offer:${JSON.stringify(offer, null, 4)}\n\nAsset:${JSON.stringify(
      asset,
      null,
      4
    )}\n\nUser:${JSON.stringify(user, null, 4)}`;

    emailService.sendEmail({
      senderEmail: workEmail,
      message: connectMessage,
      senderName: senderFullName,
      receiverEmail: contactEmail,
      title: `[Simetria Trading] ${user?.firstName} would like to Connect`,
      templateId: process.env.REACT_APP_EMAILJS_OFFER_CONNECT_CREATE_TEMPLATE_ID || "",
    });

    openConnectDialog();
  };
  return (
    <Stack direction="row" spacing={4}>
      <ConnectDialog closeDialog={closeConnectDialog} isOpen={isConnectDialogOpen} />
      <Button variant="outlined" onClick={onConnectClicked}>
        Connect
      </Button>
    </Stack>
  );
};
