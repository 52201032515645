import { Components, Theme } from "@mui/material";

export const MuiAutocomplete: Components<Theme>["MuiAutocomplete"] = {
  styleOverrides: {
    root: {
      "&, &:hover, &:focus, &.Mui-focused": {
        backgroundColor: "white",
      },
    },
    inputRoot: {
      paddingRight: "0 !important",
      paddingTop: "0 !important",
      paddingLeft: "0 !important",
    },
    endAdornment: {
      top: "calc(50% - 5px)"
    },
    input: {
      padding: "8px 32px 8px 12px !important",
    },
  },
};
