import * as React from "react";

import { userService, SettingService, emailService } from "../services";
import { commonHooks } from "@simetria/common";
import { UserContext } from "../context/user.context";
import { User, UserStatusEnum, UserTypeEnum } from "../types";

const { useAuth } = commonHooks;
const { useCallback, useState, useContext, useEffect } = React;

export const useAuthenticateUser = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canAccessApp, setCanAccessApp] = useState<boolean | null>(null);

  const { auth0Id, oauth0User, getAccessTokenSilently } = useAuth();
  const userContext = useContext(UserContext);
  const { setUser } = userContext;
  const authUserEmail = oauth0User?.email;

  const auhtenticateUser = useCallback(
    async (authId: string, authUserEmail: string) => {
      setIsLoading(true);
      let canAccessApp = false;
      let user: User | undefined;
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem("accessToken", accessToken);
        if (authId) {
          // get existing user
          const getUserByAuthIdResponse = await userService.getUserByAuthId({
            authId,
            email: authUserEmail,
          });

          user = getUserByAuthIdResponse.data;
        }
      } catch (error) {
        // create user
        const settings = await SettingService.getSettings();
        const { autoApproveUser, contactEmail } = settings;
        const createUserRequestBody: Partial<User> = {
          authId: authId,
          workEmail: oauth0User?.email,
          personalEmail: oauth0User?.email,
          firstName: oauth0User?.given_name,
          lastName: oauth0User?.family_name,
          status: autoApproveUser ? UserStatusEnum.approved : UserStatusEnum.waitingForApproval,
          emailVerified: oauth0User?.email_verified,
          type: UserTypeEnum.broker,
        };

        const createUserRespone = await userService.createUser(createUserRequestBody);
        user = createUserRespone.data;
        emailService.sendEmail({
          senderEmail: user.workEmail,
          receiverEmail: contactEmail,
          templateId: process.env.REACT_APP_EMAILJS_SIGNUP_APPROVE_TEMPLATEID || "",
        });
      }

      canAccessApp = user?.status === UserStatusEnum.approved;

      if (setUser && user) {
        setUser(user);
      }

      setIsLoading(false);
      if (canAccessApp) {
        setCanAccessApp(canAccessApp);
      }
    },
    [setUser, getAccessTokenSilently, oauth0User]
  );

  useEffect(() => {
    if (auth0Id && authUserEmail) {
      auhtenticateUser(auth0Id, authUserEmail);
    }
  }, [auth0Id, authUserEmail, auhtenticateUser]);

  return { canAccessApp, isUserAccessLoading: isLoading };
};
