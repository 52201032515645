import { captureException as sentryCaptureException } from "@sentry/browser";

import { apiURLs } from "../constants";
import { httpService } from "./http.service";
import { firebaseService } from "./firebase.service";
import { ObserveToken } from "./types/token.interface";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import {
  IWallet,
  IGetWalletByParamsRequestBody,
  ISetupWalletRequestParams,
} from "@simetria/models";

const isLoadingCache: Map<string, boolean> = new Map();
const walletCache: Map<string, IWallet> = new Map();

const walletUpdaterService = new UpdaterService<IWallet>(walletCache);
const isLoadingUpdaterService = new UpdaterService<boolean>(isLoadingCache);

const createWallet = async (newWallet: Partial<IWallet>) => {
  const createWalletResponse = await httpService.post<IWallet>(
    apiURLs.common.createWallet,
    newWallet
  );
  const createdWallet = createWalletResponse.data.data;

  walletCache.set(createdWallet.id, structuredClone(createdWallet));

  return createdWallet;
};

const getWalletIsLoadingById = (walletId: string, subscribe?: ReactSetterFunction): boolean => {
  let walletIsLoading = isLoadingCache.get(walletId);

  if (walletIsLoading === null || walletIsLoading === undefined) {
    isLoadingCache.set(walletId, false);
  }
  if (subscribe) {
    isLoadingUpdaterService.subscribeForUpdates(walletId, subscribe, true);
  }
  return Boolean(walletIsLoading);
};

const updateWalletIsLoadingById = (walletId: string, walletIsLoading: boolean) => {
  isLoadingCache.set(walletId, walletIsLoading);

  isLoadingUpdaterService.notifySubscribers([walletId]);
};

const getWalletByParams = async (
  body: IGetWalletByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend = false
) => {
  let walletList: IWallet[] = [];
  const { userIds, walletIds } = body;

  if (!getFromBackend) {
    const isUserIdsExists = Boolean(userIds?.length);
    const isWalletIdsExists = Boolean(walletIds?.length);

    walletCache.forEach(wallet => {
      const walletId = wallet.id;
      const userId = wallet.userId;

      let isExists = false;
      if (isUserIdsExists && isWalletIdsExists) {
        isExists = Boolean(userIds?.includes(userId) && walletIds?.includes(walletId));
      } else if (isUserIdsExists) {
        isExists = Boolean(userIds?.includes(userId));
      } else if (isWalletIdsExists) {
        isExists = Boolean(walletIds?.includes(walletId));
      }

      if (isExists) {
        walletList.push(structuredClone(wallet));
      }
    });
  }

  if (!walletList.length) {
    const response = await httpService.post<IWallet[]>(apiURLs.common.getWalletByParams, body);
    walletList = response.data.data;
    walletList.forEach(wallet => {
      walletCache.set(wallet.id, structuredClone(wallet));
    });
  }
  if (subscribe && walletList.length) {
    const walletIdsList = walletList.map(wallet => wallet.id);
    walletUpdaterService.subscribeForUpdates(walletIdsList, subscribe, isSingle);
  }

  return walletList;
};

const updateWallet = async (newWallet: IWallet) => {
  let updatedWallet = walletCache.get(newWallet.id);
  try {
    updateWalletIsLoadingById(newWallet.id, true);

    const response = await httpService.put<IWallet>(
      apiURLs.common.updateWallet(newWallet.id),
      newWallet
    );

    updatedWallet = response.data.data;
    walletCache.set(updatedWallet.id, structuredClone(updatedWallet));

    walletUpdaterService.notifySubscribers([updatedWallet.id]);

    updateWalletIsLoadingById(newWallet.id, false);
  } catch (error) {
    updateWalletIsLoadingById(newWallet.id, false);
    sentryCaptureException(`[wallet-service] updateWallet => error:${error}`);
  }

  return updatedWallet;
};

const updateCachedWalletUser = (walletId: string, wallet: Partial<IWallet>) => {
  const cachedWallet = walletCache.get(walletId);
  if (cachedWallet) {
    const updatedWallet = { ...cachedWallet, ...wallet };
    walletCache.set(walletId, updatedWallet);
    walletUpdaterService.notifySubscribers([walletId]);
  }
};

const deleteWallet = async (walletId: string) => {
  try {
    await httpService.delete(apiURLs.common.deleteWallet(walletId));
    const cachedWallet = walletCache.get(walletId);
    if (cachedWallet) {
      walletCache.delete(walletId);
    }
  } catch (error) {
    sentryCaptureException(`[wallet-service] deleteWallet => error:${error}`);
  }
};

const setupWallet = async (body: ISetupWalletRequestParams) => {
  const certifyUserResponse = await httpService.post(apiURLs.common.setupWallet, body);
  const response = certifyUserResponse.data.data;

  return response;
};

const getUserNfts = async (userId: string): Promise<any[]> => {
  const getUserNftsResponse = await httpService.get<any[]>(apiURLs.common.getUserNfts(userId));

  return getUserNftsResponse.data.data;
};

const observeTokens: ObserveToken = (userId, callback) => {
  return firebaseService.observeTokens(userId, callback);
};

export const walletService = {
  setupWallet,
  getUserNfts,
  createWallet,
  deleteWallet,
  updateWallet,
  observeTokens,
  getWalletByParams,
  updateCachedWalletUser,
  getWalletIsLoadingById,
  updateWalletIsLoadingById,
};
