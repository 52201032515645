import { httpService } from "./http.service";
import { apiURLs } from "../constants/api-urls";
import { IActivityLog } from "@simetria/models";

const cache: Map<string, IActivityLog> = new Map();

const getActivityLog = async (id: string) => {
  let cachedActivityLog = cache.get(id);
  if (cachedActivityLog) {
    return cachedActivityLog;
  } else {
    const response = await httpService.get<IActivityLog>(apiURLs.common.activityLogBaseUrl(id));
    const activityLog = response.data.data;

    if (activityLog) {
      cache.set(activityLog.id, structuredClone(activityLog));
    }
    return activityLog;
  }
};

const createActivityLog = async (requestBody: Partial<IActivityLog>) => {
  const response = await httpService.post<IActivityLog>(
    apiURLs.common.createActivityLog,
    requestBody
  );
  const createdActivityLog = response.data.data;
  cache.set(createdActivityLog.id as string, structuredClone(createdActivityLog));
  return createdActivityLog;
};

export const activityLogService = { getActivityLog, createActivityLog };
