import { SxProps, Theme, useTheme } from "@mui/material";

type Props = Record<string, any>;

type StyleRule<T extends string> = Record<T, SxProps>;

type StyleRules<T extends string> = ((theme: Theme, props?: Props) => StyleRule<T>) | StyleRule<T>;

export const createStyles = <T extends string>(stylingRules: StyleRules<T>) => {
  return function useStyles(props?: Props) {
    const theme = useTheme();
    return typeof stylingRules === "function" ? stylingRules(theme, props) : stylingRules;
  };
};
