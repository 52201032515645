import { useState } from "react";
import { useFormik, FormikProvider } from "formik";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";

import { emailService, offerService } from "../../../../services";
import { OfferSummary } from "./components/OfferSummary";
import { Dialog } from "../../../../components/Dialog/Dialog";
import { CreateOfferForm } from "./components/CreateOfferForm";
import formatNumber, {
  createAskValidationSchema,
  getInitialValues,
  toEnumByString,
} from "./helpers";
import { CreateOfferDialogPropos, CreateOfferStepsEnum, ICreateOfferForm } from "../../types";
import {
  Offer,
  OfferStatusEnum,
  OfferTypeEnum,
  ShareClassEnum,
  StructureTypeEnum,
} from "../../../../types";
import { useSettings } from "../../../../hooks/use-settings";
import { useUser } from "../../../../hooks";

export const CreateOfferDialog = ({
  fetchData,
  handleClose,
  offerType,
  allAssets,
  ...rest
}: CreateOfferDialogPropos) => {
  const [step, setStep] = useState(CreateOfferStepsEnum.Create);
  const {
    settings: { autoApproveOffer, contactEmail },
  } = useSettings();
  const {
    user: { firstName, workEmail, lastName, type: userType },
  } = useUser();
  const onSubmit = async (values: ICreateOfferForm) => {
    const {
      companyName,
      shareClass,
      structure,
      requestedValuation,
      shareAmountTo,
      shareAmountFrom,
      requestedSharePrice,
      offerAmountToSellFrom,
      offerAmountToSellTo,
    } = values;
    const shareClasses = shareClass?.split(" + ") as string[];
    const shareClassesEnum = shareClasses
      ?.map(shareClass => toEnumByString(ShareClassEnum, shareClass))
      .filter(Boolean);

    const asset = allAssets.find(asset => asset?.name === companyName);
    const creationDate = new Date().getTime();
    let size = "";

    if (offerAmountToSellFrom && offerAmountToSellTo) {
      const isOfferAmountRange = offerAmountToSellFrom !== offerAmountToSellTo;

      if (isOfferAmountRange) {
        const from = formatNumber(Number(offerAmountToSellFrom));
        const to = formatNumber(Number(offerAmountToSellTo));
        size = `$${from}-$${to}`;
      } else {
        size = "$" + formatNumber(Number(offerAmountToSellTo));
      }
    }

    const offer: Partial<Offer> = {
      size: size,
      creationDate,
      currency: "$",
      type: offerType,
      assetId: asset?.id!,
      shareClass: shareClassesEnum,
      valuation: requestedValuation,
      maxShareAmount: shareAmountTo,
      minShareAmount: shareAmountFrom,
      sharePrice: requestedSharePrice,
      structure: toEnumByString(StructureTypeEnum, structure),
      status: autoApproveOffer ? OfferStatusEnum.approved : OfferStatusEnum.waitingForApproval,
      userType: userType,
    };
    const createOfferResponse = await offerService.createOffer(offer);

    const createdOffer = createOfferResponse.data;

    await fetchData();

    if (workEmail && !autoApproveOffer) {
      let fullName = "";
      if (firstName) {
        fullName += firstName;
      }

      if (lastName) {
        fullName += lastName;
      }
      emailService.sendEmail({
        senderEmail: workEmail,
        templateId: process.env.REACT_APP_EMAILJS_OFFER_CONNECT_CREATE_TEMPLATE_ID || "",
        receiverEmail: contactEmail,
        senderName: `${fullName}`,
        message: `Bid/Ask Details:\n
        ID: ${createdOffer.id}
        Company Name:${asset?.name}
        ${createdOffer.valuation ? `Est. Valuation: ${formatNumber(createdOffer.valuation)}` : ``} 
        Requested Share Price: ${createdOffer.sharePrice} 
        Share Class: ${createdOffer.shareClass} 
        Offered Amount to ${createdOffer.type === OfferTypeEnum.ask ? "Buy" : "Sell"}: ${
          createdOffer.size
        }
        Structure: ${createdOffer.structure}
        Shares Amount: ${createdOffer.minShareAmount}-${createdOffer.maxShareAmount}
        ${
          createdOffer.creationDate ? `Creation Date: ${new Date(createdOffer.creationDate)}` : ``
        }`,
        title: `[Simetria Trading] ${createdOffer.type === OfferTypeEnum.ask ? "An" : "A"} ${
          createdOffer.type
        }was created by ${fullName}`,
      });
    }
    handleClose?.();
  };

  const formik = useFormik<ICreateOfferForm>({
    initialValues: getInitialValues(),
    validateOnMount: true,
    validationSchema: createAskValidationSchema,
    onSubmit,
  });

  const onNextClick = () => {
    if (step === CreateOfferStepsEnum.Create) {
      setStep(CreateOfferStepsEnum.Summary);
    } else {
      formik.submitForm();
    }
  };

  const onBackClick = () => {
    setStep(CreateOfferStepsEnum.Create);
  };

  const createStep = step === CreateOfferStepsEnum.Create;
  const summaryStep = step === CreateOfferStepsEnum.Summary;
  const isBid = offerType === OfferTypeEnum.bid;

  return (
    <FormikProvider value={formik}>
      <Dialog
        {...rest}
        handleClose={handleClose}
        maxWidth="md"
        title={`Create ${isBid ? "Bid" : "Ask"}`}
        content={
          <Box height={604}>
            {createStep && <CreateOfferForm formik={formik} isBid={isBid} allAssets={allAssets} />}
            {summaryStep && <OfferSummary isBid={isBid} formValues={formik.values} />}
            {formik.isSubmitting && (
              <Backdrop open sx={{ zIndex: 2000 }}>
                <Box
                  sx={{
                    position: "absolute",
                    inset: 0,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Backdrop>
            )}
          </Box>
        }
        actions={
          <>
            <Button disabled={createStep} variant="outlined" onClick={onBackClick}>
              Back
            </Button>
            <Button disabled={!formik.isValid || formik.isSubmitting} onClick={onNextClick}>
              Next
            </Button>
          </>
        }
      />
    </FormikProvider>
  );
};
