import * as React from "react";

import { Country, State, City, ICountry, ICity, IState } from "country-state-city";

const { useEffect, useState } = React;

interface ILocationFIeldProps {
  country: string | undefined;
}

export const useCountryStateCity = ({ country }: ILocationFIeldProps) => {
  const [allCities, setAllCities] = useState<ICity[]>();
  const [allStates, setAllStates] = useState<IState[]>();
  const [allCountries, setAllCountries] = useState<ICountry[]>();

  const [possibleCities, setPossibleCities] = useState<ICity[]>();
  const [possibleStates, setPossibleStates] = useState<IState[]>();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();

  useEffect(() => {
    const cities = City.getAllCities();
    const states = State.getAllStates();
    const countries = Country.getAllCountries();

    setAllStates(states);
    setAllCities(cities);
    setAllCountries(countries);
  }, []);

  useEffect(() => {
    if (allCountries && allCountries?.length > 0) {
      let possibleSelections = allCountries.filter(
        (countryData: ICountry) => countryData.name === country
      );

      if (possibleSelections.length > 0) {
        setSelectedCountry(possibleSelections[0]);
      }
    }
  }, [country, allCountries]);

  useEffect(() => {
    if (selectedCountry && allStates && allCities) {
      const availableStates = allStates.filter(
        (state: IState) => state.countryCode === selectedCountry.isoCode
      );

      const availableCities = allCities.filter(
        (city: ICity) => city.countryCode === selectedCountry.isoCode
      );

      availableCities.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });

      availableStates.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });

      setPossibleStates(availableStates);
      setPossibleCities(availableCities);
    }
  }, [allStates, allCities, selectedCountry]);

  return {
    citiesData: possibleCities,
    statesData: possibleStates,
    countriesData: allCountries,
  };
};
