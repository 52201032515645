import { GridRenderCellParams } from "@mui/x-data-grid";

import { OfferTypeEnum } from "../../../types";
import { Tag } from "../../../components/Tag/Tag";
import { OffersGridRow } from "../../../pages/Marketplace/types";

export const TypeCellRenderer = ({ row }: GridRenderCellParams<OffersGridRow>) => {
  const { type } = row.offer;
  const tagColor = type === OfferTypeEnum.ask ? "red" : "green";

  return <Tag color={tagColor}>{type}</Tag>;
};
