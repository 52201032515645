import { useAuth0 } from "@auth0/auth0-react";

export const useAuth = () => {
  const { logout, isAuthenticated, user: oauth0User, ...rest } = useAuth0();

  const auth0Id: string | undefined = oauth0User?.sub;

  return {
    auth0Id,
    oauth0User,
    isAuth: isAuthenticated,
    logout: () => logout({ returnTo: window.location.origin }),
    ...rest,
  };
};
