import {
  ISaleUser,
  EntityNameEnum,
  IUserDocSignature,
  IUserSaleAndDocSigIds,
  UserDocSignatureStatus,
  ICheckResetDocumentsData,
  ICancelSignatureResponse,
  ISignedUserDocNotification,
  ICancelSignatureRequestBody,
  IUserDocSignatureBaseParams,
  IUserDocSigGetByParamsRequestBody,
} from "@simetria/models";
import { httpService } from "./http.service";
import { userService } from "./user.service";
import { saleService } from "./sale.service";
import { compareObjectsFlat } from "../utils";
import { apiURLs } from "../constants/api-urls";
import { firebaseService } from "./firebase.service";
import { saleUserService } from "./sale-user.service";
import { userBankDetailsService } from "./user-bank-details.service";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { captureMessage as sentryCaptureMessage } from "@sentry/browser";
import { ObserveUserDocuments } from "./types/user-doc-signature.interface";

const isLoadingCache: Map<string, boolean> = new Map();
const docSignatureCache: Map<string, IUserDocSignature> = new Map();

const isLoadingUpdaterService = new UpdaterService<boolean>(isLoadingCache);
const updaterService = new UpdaterService<IUserDocSignature>(docSignatureCache);

const getUserDocSignatureIsLoadingById = async (
  docId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
): Promise<any> => {
  let UserDocSignatureIsLoading = isLoadingCache.get(docId);

  if (UserDocSignatureIsLoading === null || UserDocSignatureIsLoading === undefined) {
    isLoadingCache.set(docId, false);
  }
  if (subscribe) {
    isLoadingUpdaterService.subscribeForUpdates(docId, subscribe, isSingle);
  }
  return UserDocSignatureIsLoading;
};

const updateUserDocSignatureIsLoadingById = async (
  docId: string,
  UserDocSignatureIsLoading: boolean
) => {
  await isLoadingCache.set(docId, UserDocSignatureIsLoading);

  isLoadingUpdaterService.notifySubscribers([docId]);
};

// TODO(ravid & moshe): notify subscribers
const removeFromCache = (ids: string[]) => {
  ids.forEach(id => {
    docSignatureCache.delete(id);
  });
};

const getDocumentsSignByParams = async (
  userId: string,
  params: IUserDocSigGetByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend: boolean = false
) => {
  let documentsSign: IUserDocSignature[] = [];

  if (getFromBackend) {
    const response = await httpService.post<IUserDocSignature[]>(
      apiURLs.common.getUserSignDocumentsByParams(userId),
      {
        saleId: params?.saleId,
        id: params?.id,
        status: params?.status,
        userId: params?.userId,
        userAssetIdList: params.userAssetIdList,
      }
    );
    documentsSign = response.data.data;

    documentsSign.forEach(documentSign =>
      docSignatureCache.set(documentSign.id, structuredClone(documentSign))
    );
  } else {
    let isValid = false;
    const isIdExists = Boolean(params.id?.length);
    const isUserIdExists = Boolean(params.userId?.length);
    const isSaleIdExists = Boolean(params.saleId?.length);
    const isStatusExists = Boolean(params.status?.length);
    const isTemplateIdListExists = Boolean(params.templateIdList?.length);
    const isUserAssetListExists = Boolean(params.userAssetIdList?.length);

    docSignatureCache.forEach(documentSign => {
      if (isIdExists) {
        isValid = params.id!.includes(documentSign.id);
      }
      if (isValid && isUserIdExists) {
        isValid = params.userId!.includes(documentSign.userId);
      }
      if (isValid && isSaleIdExists) {
        isValid = params.saleId!.includes(documentSign.saleId);
      }
      if (isValid && isStatusExists) {
        isValid = params.status!.includes(documentSign.status);
      }
      if (isValid && isTemplateIdListExists) {
        isValid = params.templateIdList!?.includes(documentSign.documentTemplateId);
      }

      if (isValid && isUserAssetListExists && documentSign.userAssetId) {
        isValid = params.userAssetIdList!?.includes(documentSign.userAssetId);
      }

      if (isValid) {
        documentsSign.push(structuredClone(documentSign));
      }
    });
    if (!documentsSign.length) {
      const response = await httpService.post<IUserDocSignature[]>(
        apiURLs.common.getUserSignDocumentsByParams(userId),
        {
          id: params?.id,
          saleId: params?.saleId,
          status: params?.status,
          userId: params?.userId,
          templateIdList: params?.templateIdList,
          userAssetIdList: params?.userAssetIdList,
        }
      );
      documentsSign = response.data.data;

      documentsSign.forEach(documentSign =>
        docSignatureCache.set(documentSign.id, structuredClone(documentSign))
      );
    }
  }

  if (subscribe) {
    const documentSignIds = documentsSign.map(documentSign => documentSign.id);
    updaterService.subscribeForUpdates(documentSignIds, subscribe, isSingle, params);
  }
  return documentsSign;
};

const updateUserDocSign = async (userDocSignature: Partial<IUserDocSignature>) => {
  updateUserDocSignatureIsLoadingById(userDocSignature?.id!, true);

  const response = await httpService.put<IUserDocSignature>(
    apiURLs.common.userDocumentSignatureUpdateDoc(
      userDocSignature.userId as string,
      userDocSignature.id as string
    ),
    userDocSignature
  );

  const newDocumentSign = response.data.data;
  docSignatureCache.set(newDocumentSign.id, structuredClone(newDocumentSign));
  updaterService.notifySubscribers([newDocumentSign.id]);

  updateUserDocSignatureIsLoadingById(userDocSignature?.id!, false);

  return newDocumentSign;
};

const generateSigningLink = async (data: IUserDocSignatureBaseParams) => {
  const response = await httpService.post<string>(
    apiURLs.common.userDocumentSignatureSigningUrl(data.userId),
    data
  );
  return response?.data?.data;
};

const cancelDocumentSignature = async (data: ICancelSignatureRequestBody) => {
  const response = await httpService.post<ICancelSignatureResponse>(
    apiURLs.common.userDocumentSignatureCancelSig(data.userId, data.userDocSignatureId),
    data
  );
  docSignatureCache.delete(data.userDocSignatureId);
  const canceledDocumentSignResponse = response.data.data;
  if (canceledDocumentSignResponse.userDocSignarure) {
    docSignatureCache.set(
      canceledDocumentSignResponse.userDocSignarure.id,
      structuredClone(canceledDocumentSignResponse.userDocSignarure)
    );

    updaterService.notifySubscribers([canceledDocumentSignResponse.userDocSignarure.id]);
  }

  return canceledDocumentSignResponse;
};

const checkDocumentsToReset = async (data: ICheckResetDocumentsData) => {
  const response = await httpService.post<string[]>(apiURLs.common.checkDocumentsToReset, data);
  return response.data.data;
};

const resetDocuments = async (data: IUserSaleAndDocSigIds) => {
  if (data.userDocSignatureIdList) {
    removeFromCache(data.userDocSignatureIdList);
  }

  const response = await httpService.post<IUserDocSignature[]>(apiURLs.common.resetDocuments, data);
  const newOrUpdatedDocSigs = response.data.data;
  if (newOrUpdatedDocSigs) {
    newOrUpdatedDocSigs.forEach(newOrUpdatedDocSig => {
      docSignatureCache.set(newOrUpdatedDocSig.id, structuredClone(newOrUpdatedDocSig));
    });
  }

  updaterService.notifySubscribers(
    newOrUpdatedDocSigs.map(newOrUpdatedDocSig => newOrUpdatedDocSig.id)
  );

  return newOrUpdatedDocSigs;
};

const getUserDocSignatureIdsThatNeedReset = async (
  userId: string,
  saleId: string,
  objectA: any,
  objectB: any,
  objectsType: EntityNameEnum
) => {
  let userDocSignatureIdsThatNeedReset: string[] = [];
  const changedProperties = compareObjectsFlat(objectA, objectB);
  if (changedProperties.length) {
    const changedEntityAndProperties: Record<string, string[]> = {};
    changedEntityAndProperties[objectsType] = changedProperties;
    userDocSignatureIdsThatNeedReset = await userDocSignatureService.checkDocumentsToReset({
      saleId: saleId,
      userId: userId,
      changedFields: changedEntityAndProperties,
    });
  }
  return userDocSignatureIdsThatNeedReset;
};

const observeUserDocuments: ObserveUserDocuments = (userId, callback) => {
  return firebaseService.observeUserDocuments(userId, updateUserDocuments(callback));
};

const updateUserDocuments = (callback: () => void) => {
  return (signedDocument: ISignedUserDocNotification) => {
    const documentSign = docSignatureCache.get(signedDocument.userDocSignatureId);
    if (documentSign) {
      if (documentSign.status !== signedDocument.userDocSignatureStatus) {
        documentSign.status = signedDocument.userDocSignatureStatus;
        updaterService.notifySubscribers([signedDocument.userDocSignatureId]); // update listeners
      }

      if (documentSign.status === UserDocSignatureStatus.signed) {
        callback();
      }
    }
    // update saleuser
    if (signedDocument.saleUserId) {
      const updatedSaleUser: Partial<ISaleUser> = {
        reviewAndSignReadyForSale: signedDocument.reviewAndSignReadyForSale,
      };
      if (signedDocument.saleUserStatus) {
        updatedSaleUser.status = signedDocument.saleUserStatus;
      }

      if (signedDocument.saleUserDocGenerationRequired) {
        updatedSaleUser.documentGenerationRequired = signedDocument.saleUserDocGenerationRequired;
      }

      saleUserService.updateCachedSaleUser(signedDocument.saleUserId, {
        ...updatedSaleUser,
      });
    }
  };
};

const checkforMissingDependencies = (
  userDocSignature: IUserDocSignature,
  requiredEntities: Record<string, any>
): string[] => {
  const missingDependencies: string[] = [];

  userDocSignature.dependency?.forEach(entityAndProperty => {
    let missingDependency: string = "";
    const [entityName, propertyName] = entityAndProperty.split("_");

    if (!requiredEntities[entityName]) {
      missingDependency = `${entityName}_${propertyName}`;

      missingDependencies.push(missingDependency);
    } else if (!Boolean(requiredEntities[entityName][propertyName])) {
      missingDependency = `${entityName}_${propertyName}`;

      missingDependencies.push(missingDependency);
    }
  });

  return missingDependencies;
};

/**
 * Get the missing dependencies for a userDocSignature.
 * ***company,userGrant,saleDate are currently not handled if they are a document dependency***.
 * @param userDocSignature the document we want find missing dependencies for
 * @returns an array of missing dependencies in the following format [entityName1_propertyName1, entityName2_propertyName2, ...]
 */
const getMissingDependencies = async (userDocSignature: IUserDocSignature): Promise<string[]> => {
  let missingDependencies: string[] = [];
  let promiseArray: Promise<void>[] = [];
  let requiredEntitiesNameList = new Set<string>();
  let requiredEntities: Record<string, any> = {} as Record<string, any>;

  const { userId, saleId } = userDocSignature;

  if (userDocSignature.dependency?.length) {
    // get all the required entity names for the document
    userDocSignature.dependency?.forEach(dependency => {
      const entityName = dependency.split("_")[0]; // [0] is the entity [1] is the property
      requiredEntitiesNameList.add(entityName);
    });

    // get all the required DB entities
    requiredEntitiesNameList.forEach(requiredEntityName => {
      switch (requiredEntityName) {
        case EntityNameEnum.sale:
          const salePromise = new Promise<void>(async resolve => {
            const salesResponse = await saleService.getSalesByIds([saleId], undefined, true);
            if (salesResponse.length) {
              requiredEntities[EntityNameEnum.sale] = salesResponse[0];
            }

            resolve();
          });

          promiseArray.push(salePromise);
          break;

        case EntityNameEnum.saleUser:
          const saleUserPromise = new Promise<void>(async resolve => {
            const saleUsersResponse = await saleUserService.getSalesUserByParams(
              {
                saleIds: [saleId],
                userIds: [userId],
              },
              undefined,
              true
            );
            if (saleUsersResponse.length) {
              if (saleUsersResponse.length > 1) {
                sentryCaptureMessage(
                  `[user-doc-signature-service] canUserSignDocument => more than one sale user found`
                );
              }
              requiredEntities[EntityNameEnum.saleUser] = saleUsersResponse[0];
            }
            resolve();
          });

          promiseArray.push(saleUserPromise);
          break;

        case EntityNameEnum.userBankAccountDetails:
          const bankDetailsPromise = new Promise<void>(async resolve => {
            requiredEntities[EntityNameEnum.userBankAccountDetails] =
              await userBankDetailsService.getBankDetails(userId);

            resolve();
          });

          promiseArray.push(bankDetailsPromise);
          break;

        case EntityNameEnum.userProfile:
          const userProfilePromise = new Promise<void>(async resolve => {
            requiredEntities[EntityNameEnum.userProfile] = await userService.getUserById(userId);

            resolve();
          });
          promiseArray.push(userProfilePromise);
          break;

        default:
          sentryCaptureMessage(
            `[user-doc-signature-service] canUserSignDocument => unknown entity name:${requiredEntityName}`
          );
      }
    });

    await Promise.all(promiseArray);

    // check which dependencies are missing
    missingDependencies = checkforMissingDependencies(userDocSignature, requiredEntities);
  }

  return missingDependencies;
};

export const userDocSignatureService = {
  resetDocuments,
  removeFromCache,
  updateUserDocSign,
  generateSigningLink,
  observeUserDocuments,
  checkDocumentsToReset,
  getMissingDependencies,
  cancelDocumentSignature,
  getDocumentsSignByParams,
  getUserDocSignatureIsLoadingById,
  getUserDocSignatureIdsThatNeedReset,
};
