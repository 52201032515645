import * as React from "react";
import { User } from "../types";

export interface IUserContext {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const { useState, createContext } = React;

export const UserContext = createContext({} as IUserContext);

export const UserProvider = ({ children }: React.PropsWithChildren) => {
  const [user, setUser] = useState<User>({} as User);
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
