import * as React from "react";
import { Toast, ToastMessage } from "primereact/toast";

import styles from "./simetria-toaster.module.scss";

const { useRef, useEffect } = React;

const defaultToastMSGLifespanInMili: number = 3000;

export const SimetriaToaster = ({ toastMessage }: { toastMessage: ToastMessage }): JSX.Element => {
  const myToast: any = useRef(null);

  useEffect(() => {
    if (toastMessage.detail) {
      let newToastMessege = { ...toastMessage };
      if (!newToastMessege.sticky) {
        newToastMessege.life = defaultToastMSGLifespanInMili;
      }

      myToast.current.show({ ...toastMessage });
    }
  }, [toastMessage]);

  return <Toast id="toaster-container" className={styles.toasterBox} ref={myToast} />;
};
