import { Components, Theme } from "@mui/material";

export const MuiToolbar: Components<Theme>["MuiToolbar"] = {
  styleOverrides: {
    root: {
      paddingLeft: "24px !important",
      paddingRight: "24px !important",
    },
  },
};
