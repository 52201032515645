import { httpService } from "./http.service";
import { apiURLs } from "../constants/api-urls";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { ICompany, IGetCompanyByParamsRequestBody } from "@simetria/models";

const cache: Map<string, ICompany> = new Map();

const updaterService = new UpdaterService<ICompany>(cache);

const getCompanyById = async (
  companyId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let company = cache.get(companyId);
  if (!company) {
    const response = await httpService.get<ICompany>(apiURLs.company.companyById(companyId));
    company = response.data.data;
    cache.set(company.id, company);
  }
  if (subscribe) {
    updaterService.subscribeForUpdates(companyId, subscribe, isSingle);
  }
  return company;
};

const updateCompany = async (company: ICompany) => {
  const response = await httpService.post<ICompany>(
    apiURLs.company.companyById(company.id),
    company
  );

  const updatedCompany = response.data.data;
  cache.set(updatedCompany.id, structuredClone(updatedCompany));
  updaterService.notifySubscribers([updatedCompany.id]);
  return updatedCompany;
};

const createCompany = async (company: Partial<ICompany>) => {
  const response = await httpService.post<ICompany>(apiURLs.company.createCompany, company);
  const createdCompany = response.data.data;
  cache.set(createdCompany.id, structuredClone(createdCompany));

  return createdCompany;
};

const getCompanyByParams = async (
  requestBody: IGetCompanyByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  const isCompanyIdsExists = Boolean(requestBody.companyIds?.length);
  const isOrganizationIdExists = Boolean(requestBody.organizationId);
  let companies: ICompany[] = [];
  cache.forEach(company => {
    const organizationId = company.organizationId;
    const companyId = company.id;
    let isExists = false;
    if (isCompanyIdsExists && isOrganizationIdExists) {
      isExists = Boolean(
        requestBody.organizationId === organizationId && requestBody.companyIds?.includes(companyId)
      );
    } else if (isOrganizationIdExists) {
      isExists = Boolean(requestBody.organizationId === organizationId);
    } else if (isCompanyIdsExists) {
      isExists = Boolean(requestBody.companyIds?.includes(companyId));
    }
    if (isExists) {
      companies.push(structuredClone(company));
    }
  });
  if (!companies.length) {
    const response = await httpService.post<ICompany[]>(
      apiURLs.company.getCompanyByParams,
      requestBody
    );
    companies = response.data.data;
    companies.forEach(company => {
      cache.set(company.id, structuredClone(company));
    });
  }
  if (subscribe) {
    const companiesIds = companies.map(company => company.id);
    updaterService.subscribeForUpdates(companiesIds, subscribe, isSingle);
  }

  return companies;
};

export const companyService = {
  updateCompany,
  createCompany,
  getCompanyById,
  getCompanyByParams,
};
