export const apiURLs = {
  common: {
    // Sale
    companySaleGetMany: "sale/get-many",
    companySales: (companyId: string) => `company/${companyId}/sale`,
    companySale: (companyId: string, saleId: string) => `company/${companyId}/sale/${saleId}`,
    updateSaleParticipationSettings: (companyId: string) =>
      `company/${companyId}/sale/update-sale-paticipation-settings`,
    prepareSaleKit: (saleId: string) => `sale/${saleId}/prepare-sale-kit-input`,

    // Sale Dates
    createSaleDate: "sale-date",
    getSaleDateByParams: "sale-date/get-sale-date-by-params",
    createOrUpdateSaleDate: "sale-date/create-or-update-list",
    saleDateBaseUrl: (saleDateId: string) => `sale-date/${saleDateId}`,

    // Document template
    documentTemplateGetAll: "document-template/",
    documentTemplateGetMany: "document-template/get-many",
    documentTemplateGetByParams: `document-template/get-document-template-by-params`,

    // User
    user: "user",
    userByAuthId: `user/get-by-auth-id`,
    userById: (id: string) => `user/${id}`,
    getUsersByParams: "user/get-by-params",

    // UserDocument
    userRequiredDoc: (userId: string) => `user/${userId}/document`,
    userUpdateRequiredDoc: (userId: string, documentId: string) =>
      `user/${userId}/document/${documentId}`,
    getUserDocuments: (userId: string) => `user/${userId}/document`,
    crateUserDocument: (userId: string) => `user/${userId}/document`,
    getUserDocumentsByParams: "document/get-user-document-by-params",
    getUserDocument: (userId: string, docId: string) => `user/${userId}/document/${docId}`,
    updateUserDocument: (userId: string, docId: string) => `user/${userId}/document/${docId}`,
    deleteUserDocument: (userId: string, docId: string) => `user/${userId}/document/${docId}`,
    downloadUserDocument: `user-document-download`,

    // UserDocSignaure
    resetDocuments: "document-sign/reset-documents",
    checkDocumentsToReset: "document-sign/check-reset-documents",
    getDocumentOrTemplate: "document-sign/get-document-or-template",
    userDocumentSignatureCancelSig: (userId: string, documentId: string) =>
      `user/${userId}/document-sign/${documentId}/cancel-signature`,
    getDocumentAsBinary: (userId: string, documentId: string) =>
      `user/${userId}/document-sign/${documentId}/show-read-only`,
    userDocumentSignatureSigningUrl: (userId: string) =>
      `user/${userId}/document-sign/generate-invite-link`,
    userDocumentSignatureUpdateDoc: (userId: string, documentId: string) =>
      `user/${userId}/document-sign/${documentId}`,
    userDocumentSignatureDownloadDocument: (userId: string, documentId: string) =>
      `user/${userId}/document-sign/${documentId}/get-download-data`,
    userDocumentSignatureAllDocs: (userId: string) => `user/${userId}/document-sign`,
    getUserSignDocumentsByParams: (userId: string) =>
      `user/${userId}/document-sign/get-user-doc-signatures-by-params`,

    // UserRole
    createUserRoleByParams: "user-role",
    getUserRoleByParams: "user-role/get-by-params",

    // SaleUser
    saleUseBaseUrl: (bidId: string) => `sale-user/${bidId}`,
    updateSaleUserUrl: (saleUserId: string) => `sale-user/${saleUserId}`,

    // SaleUserOutcome
    createSaleUserOutcome: "sale-user-outcome/",
    saleUserOutcomeBaseUrl: (saleUserOutcomeId: string) => `sale-user-outcome/${saleUserOutcomeId}`,

    // Bid
    createBid: "bid",
    getBidByParams: "bid/get-bid-by-params",
    bidBaseUrl: (bidId: string) => `bid/${bidId}`,

    //Activity Log
    createActivityLog: "activity-log",
    activityLogBaseUrl: (id: string) => `activity-log/${id}`,

    // Deal
    createDeal: "deal",
    getDealByParams: "deal/get-deal-by-params",
    dealBaseUrl: (dealId: string) => `deal/${dealId}`,

    //User Grants
    createUserGrant: "/user-grant",
    getUserGrantByParams: "/user-grant/get-by-params",
    userGrantBaseUrl: (grantId: string) => `user-grant/${grantId}`,

    //Company
    getCompanyByParams: `company/get-company-by-params`,

    //Wallet
    createWallet: `wallet`,
    setupWallet: "wallet/setup-wallet",
    getWalletByParams: `wallet/get-wallet-by-params`,
    deleteWallet: (walletId: string) => `wallet/${walletId}`,
    updateWallet: (walletId: string) => `wallet/${walletId}`,
    getUserNfts: (userId: string) => `wallet/get-user-nfts/${userId}`,

    //UserAsset
    createUserAsset: `user-asset`,
    setupUserAsset: `user-asset/setup-user-asset`,
    getUserAssetByParams: `user-asset/get-user-asset-by-params`,
    getUserAsset: (userAssetId: string) => `user-asset/${userAssetId}`,
    deleteUserAsset: (userAssetId: string) => `user-asset/${userAssetId}`,
    updateUserAsset: (userAssetId: string) => `user-asset/${userAssetId}`,

    //Transaction
    createTransaction: `transaction`,
    getTransactionByParams: `transaction/get-transaction-by-params`,
    updateTransaction: (transactionId: string) => `transaction/${transactionId}`,
    deleteTransaction: (transactionId: string) => `transaction/${transactionId}`,

    // Settings
    getFirebaseConfig: `settings/firebase`,
  },

  company: {
    getSaleUserByParams: "sale-user/get-by-params",
    createOrUpdateUserSales: "sale-user/create-or-update",

    getUserRoleInCompany: "user-role/get-roles-by-user-id",
    getUserRolesInCompany: "user-role/get-company-roles-by-status",

    createCompany: "company",
    getCompanyByParams: "company/get-company-by-params",
    companyById: (companyId: string) => `company/${companyId}`,
  },
};
