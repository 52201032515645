import { Components, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: {
      backgroundColor: "white",
      boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.10)"
    }
  },
};
