import { apiURLs } from "../constants/api-urls";
import { IGetUserByAuthIdRequestBody, IUserProfile } from "@simetria/models";

import { httpService } from "./http.service";
import { ReactSetterFunction, UpdaterService } from "./updater.service";

const { user, userById, userByAuthId, getUsersByParams } = apiURLs.common;

const userCache: Map<string, IUserProfile> = new Map();
const isLoadingCache: Map<string, boolean> = new Map();

const updaterService = new UpdaterService<IUserProfile>(userCache);
const isLoadingUpdateService = new UpdaterService<boolean>(isLoadingCache);

const getUserByAuthId = async (body: IGetUserByAuthIdRequestBody) => {
  const { authId } = body;
  let user;
  for (const [, cachedUser] of userCache) {
    if (cachedUser.authId === authId) {
      user = structuredClone(cachedUser);
      break;
    }
  }
  if (!user) {
    const response = await httpService.post<IUserProfile>(userByAuthId, body);
    user = response.data.data;
    userCache.set(user.id as string, structuredClone(user));
  }
  return user as IUserProfile;
};

const getAllUsers = async () => {
  return httpService.get<IUserProfile[]>(user); // need to revisit
};

const getUserById = async (
  id: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend: boolean = false
): Promise<IUserProfile> => {
  let user;
  for (const [, cachedUser] of userCache) {
    if (cachedUser.id === id) {
      user = structuredClone(cachedUser);
      break;
    }
  }
  if (!user || getFromBackend) {
    const response = await httpService.get<IUserProfile>(userById(id));
    user = response.data.data;
    userCache.set(user.id as string, structuredClone(user));
  }
  if (subscribe) {
    updaterService.subscribeForUpdates(id, subscribe, isSingle);
  }
  return user;
};

const getUserIsLoadingById = (userId: string, subscribe?: ReactSetterFunction): boolean => {
  let userIsLoading: boolean = isLoadingCache.get(userId)!;

  if (userIsLoading === null || userIsLoading === undefined) {
    isLoadingCache.set(userId, false);
  }

  if (subscribe) {
    isLoadingUpdateService.subscribeForUpdates(userId, subscribe, true);
  }
  return Boolean(userIsLoading);
};

const updateUserIsLoadingById = async (userId: string, userIsLoading: boolean) => {
  await isLoadingCache.set(userId, userIsLoading);

  isLoadingUpdateService.notifySubscribers([userId]);
};

const getManyUsers = async (
  userIds: string[],
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let users: IUserProfile[] = [];
  userCache.forEach(user => {
    if (userIds.includes(user.id as string)) {
      users.push(structuredClone(user));
    }
  });
  if (users.length !== userIds.length) {
    const response = await httpService.post<IUserProfile[]>(getUsersByParams, { userIds });
    users = response.data.data;
    users.forEach(user => {
      userCache.set(user.id as string, structuredClone(user));
    });
  }
  if (subscribe) {
    const userIds = users.map(user => user.id as string);
    updaterService.subscribeForUpdates(userIds, subscribe, isSingle);
  }
  return users;
};

const createUser = async (userData: Partial<IUserProfile>) => {
  const response = await httpService.post<IUserProfile>(user, userData);
  const createdUser = response.data.data;
  userCache.set(createdUser.id as string, structuredClone(createdUser));
  return createdUser;
};

const updateUser = async (userData: IUserProfile) => {
  updateUserIsLoadingById(userData.id!, true);
  const response = await httpService.put<IUserProfile>(userById(userData.id as string), userData);
  const updatedUser = response.data.data;
  userCache.set(updatedUser.id as string, structuredClone(updatedUser));
  await updaterService.notifySubscribers([updatedUser.id as string]);

  updateUserIsLoadingById(userData?.id!, false);
  return updatedUser;
};

const deleteUser = async (userId: string) => {
  const response = await httpService.delete(userById(userId as string));
  userCache.delete(userId as string);

  return response.data.data;
};

export const userService = {
  createUser,
  updateUser,
  deleteUser,
  getAllUsers,
  getUserById,
  getManyUsers,
  getUserByAuthId,
  getUserIsLoadingById,
};
