import { GridRenderCellParams } from "@mui/x-data-grid";

import { OffersGridRow } from "../../../pages/Marketplace/types";

const dollarSign = "$";

export const PriceCellRenderer = ({ row }: GridRenderCellParams<OffersGridRow>) => {
  const { sharePrice } = row.offer;

  if (sharePrice) {
    return dollarSign + sharePrice.toString();
  }

  return;
};
