import { useField } from "formik";
import { Checkbox, CheckboxProps } from "@mui/material";

interface CheckboxFieldProps extends CheckboxProps {
  name: string;
}

export const FormCheckbox = ({ name, ...rest }: CheckboxFieldProps) => {
  const [field] = useField(name);
  const checked = Array.isArray(field.value)
    ? field.value.includes(rest.value)
    : field.value === rest.value;
  return <Checkbox {...field} {...rest} checked={checked} />;
};
