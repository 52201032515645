import {
  IUserDocument,
  ICreateUserDocumentRequestBody,
  IGetUserDocumentByParamsRequestBody,
} from "@simetria/models";
import { httpService } from "./http.service";
import { apiURLs } from "../constants/api-urls";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { IGetUserDocumentArgs, IUpdatedUserDocumentArgs } from "../types/user-document.interface";

const {
  getUserDocument: getUserDocumentURL,
  crateUserDocument,
  updateUserDocument: updateUserDocumentURL,
  deleteUserDocument: deleteUserDocumentURL,
  downloadUserDocument: downloadUserDocumentURL,
} = apiURLs.common;

const cache: Map<string, IUserDocument> = new Map();

const updaterService = new UpdaterService<IUserDocument>(cache);

const getDocumentsByParams = async (
  requestBody: IGetUserDocumentByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  const isSaleIdsExists = Boolean(requestBody.saleIds);
  const isUserIdsExists = Boolean(requestBody.userIds);
  let documents: IUserDocument[] = [];
  cache.forEach(userDocument => {
    let isExists = false;
    if (isSaleIdsExists) {
      isExists = Boolean(requestBody.saleIds?.includes(userDocument.saleId));
    }
    if (isUserIdsExists) {
      isExists = Boolean(requestBody.userIds?.includes(userDocument.userId as string));
    }
    if (isExists) {
      documents.push(structuredClone(userDocument));
    }
  });
  if (!documents.length) {
    const response = await httpService.post<IUserDocument[]>(
      apiURLs.common.getUserDocumentsByParams,
      requestBody
    );
    documents = response.data.data;
    documents.forEach(document => {
      cache.set(document.id, structuredClone(document));
    });
  }
  if (subscribe) {
    const documentsId = documents.map(document => document.id);
    updaterService.subscribeForUpdates(documentsId, subscribe, isSingle);
  }
  return documents;
};

const getUserDocument = async (
  requestBody: IGetUserDocumentArgs,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  const { docId, userId } = requestBody;
  let document = cache.get(docId);
  if (!document) {
    const response = await httpService.get<IUserDocument>(getUserDocumentURL(userId, docId));
    document = response.data.data;
    cache.set(document.id, structuredClone(document));
  }
  return document;
};

const createUserDocument = async (body: Partial<ICreateUserDocumentRequestBody>) => {
  const response = await httpService.post<IUserDocument>(
    crateUserDocument(body?.userDocumentData?.userId!),
    body
  );
  const document = response.data.data;
  cache.set(document.id, document);
  return structuredClone(document);
};

const updateUserDocument = async (body: IUpdatedUserDocumentArgs) => {
  const response = await httpService.put<IUserDocument>(
    updateUserDocumentURL(body.userId, body.docId),
    body
  );
  const updatedDocument = response.data.data;
  cache.set(updatedDocument.id, structuredClone(updatedDocument));
  updaterService.notifySubscribers([updatedDocument.id]);
  return updatedDocument;
};

const deleteUserDocument = async ({ docId, userId }: IGetUserDocumentArgs) => {
  const response = await httpService.delete<any>(deleteUserDocumentURL(userId, docId));
  cache.delete(docId);
  return response.data.data;
};

const downloadUserDocument = async (fileName: string) => {
  const response = await httpService.post<any>(downloadUserDocumentURL, {
    fileName: fileName,
  });

  return response.data.data;
};

export const userDocumentService = {
  getUserDocument,
  createUserDocument,
  updateUserDocument,
  deleteUserDocument,
  downloadUserDocument,
  getDocumentsByParams,
};
