import * as React from "react";

import { useAuth } from "../../hooks";
import { Button, Typography } from "../../components";
import { analyticEvents } from "../../constants/analytic-events";
import { analyticsServices } from "../../services/analitics/analytics.service";

import styles from "./email-verification.page.module.scss";

import { ReactComponent as SentEmailIcon } from "../../assets/icons/sent_mail.svg";

const { useEffect } = React;

export const EmailVerificationPage = (): JSX.Element => {
  const { oauth0User } = useAuth();

  const resendEmailHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!e) return;

    analyticsServices.createMixpanelEvent(analyticEvents.onDidntReceiveTheEmailClick);
  };

  useEffect(() => {
    analyticsServices.createMixpanelEvent(analyticEvents.onVerificationEmailSentVisit);
  }, []);

  return (
    <div className={styles.signInComponent}>
      <div>
        <SentEmailIcon />

        <Typography variant="h4" display="block">
          Your account is almost ready
        </Typography>

        <Typography variant="s3" display="block">
          We sent a verification link to {oauth0User?.email}.
        </Typography>
        <Typography variant="s3" display="block">
          Please check your inbox for next steps.
        </Typography>

        <div className={styles.btnsWrap}>
          <Button variant="link" label="Don't receive email? Resend" onClick={resendEmailHandler} />
        </div>
      </div>
    </div>
  );
};
