import React from "react";
import {
  Box,
  SxProps,
  Typography,
  DialogContent,
  DialogActions,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../styles/assets/close.svg";
// @ts-ignore
export interface DialogProps extends MuiDialogProps {
  children?: React.ReactNode;
  title?: string;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  handleClose?: () => void;
  header?: React.ReactNode;
}

const styles: Record<string, SxProps> = {
  paper: {
    padding: 7,
  },
  header: { display: "flex", justifyContent: "center", width: "100%" },
  title: {
    pt: 4.5,
    pl: 0,
    pb: 4,
  },
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {
    cursor: "pointer",
  },
  content: {
    pl: 0,
    pr: 0,
    pb: 6,
  },
  actions: {
    pt: 0,
    columnGap: 1,
    justifyContent: "flex-end",
  },
};

export const Dialog = ({
  open,
  title,
  actions,
  content,
  header,
  handleClose,
  ...rest
}: DialogProps) => {
  return open ? (
    <MuiDialog open PaperProps={{ sx: styles.paper }} fullWidth maxWidth={"sm"} {...rest}>
      {handleClose && (
        <Box sx={styles.closeIconWrapper}>
          <Box sx={styles.closeIcon}>
            <CloseIcon onClick={handleClose} />
          </Box>
        </Box>
      )}
      <Box sx={styles.header}>{header}</Box>

      <Box sx={{ ...styles.title, textAlign: header ? "center" : "inherit" }}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <DialogContent sx={styles.content}>
        <Box>{content}</Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>{actions}</DialogActions>
    </MuiDialog>
  ) : null;
};
