import { httpService } from "./http.service";
import { commonConstants } from "@simetria/common";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { ISaleUserOutcome, IGetSaleUserOutcomeByParamsRequestBody } from "@simetria/models";

const cache: Map<string, ISaleUserOutcome> = new Map();

const updaterService = new UpdaterService<ISaleUserOutcome>(cache);

const getSaleUserOutcome = async (
  saleUserOutcomeId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let saleUserOutcome = structuredClone(cache.get(saleUserOutcomeId));
  if (!saleUserOutcome) {
    const response = await httpService.get<ISaleUserOutcome>(
      commonConstants.apiURLs.common.saleUserOutcomeBaseUrl(saleUserOutcomeId)
    );
    saleUserOutcome = response.data.data;
    cache.set(saleUserOutcome.id, saleUserOutcome);
  }
  if (subscribe) {
    updaterService.subscribeForUpdates(saleUserOutcomeId, subscribe, isSingle);
  }
  return saleUserOutcome;
};

const updateSaleUserOutcome = async (saleUserOutcome: ISaleUserOutcome) => {
  const response = await httpService.put<ISaleUserOutcome>(
    commonConstants.apiURLs.common.saleUserOutcomeBaseUrl(saleUserOutcome.id),
    saleUserOutcome
  );

  const updatedSaleUserOutcome = response.data.data;
  cache.set(updatedSaleUserOutcome.id, structuredClone(updatedSaleUserOutcome));
  updaterService.notifySubscribers([updatedSaleUserOutcome.id]);
  return updatedSaleUserOutcome;
};

const createSaleUserOutcome = async (saleUserOutcome: Omit<ISaleUserOutcome, "id">) => {
  const response = await httpService.put<ISaleUserOutcome>(
    commonConstants.apiURLs.common.createSaleUserOutcome,
    saleUserOutcome
  );

  const newSaleUserOutcome = response.data.data;
  cache.set(newSaleUserOutcome.id, structuredClone(newSaleUserOutcome));
  updaterService.notifySubscribers([newSaleUserOutcome.id]);
  return newSaleUserOutcome;
};

const getSaleUserOutcomeByParams = async (
  body: IGetSaleUserOutcomeByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend = false
) => {
  let saleUserOutcomes: ISaleUserOutcome[] = [];

  if (!getFromBackend) {
    const isUserIdsExists = Boolean(body.userIds?.length);
    const isSaleIdsExists = Boolean(body.saleIds?.length);
    cache.forEach(saleUserOutcome => {
      const saleId = saleUserOutcome.saleId;
      const userId = saleUserOutcome.userId;
      let isExists = false;
      if (isUserIdsExists && isSaleIdsExists) {
        isExists = Boolean(body.userIds?.includes(userId) && body.saleIds?.includes(saleId));
      } else if (isUserIdsExists) {
        isExists = Boolean(body.userIds?.includes(userId));
      } else if (isSaleIdsExists) {
        isExists = Boolean(body.saleIds?.includes(saleId));
      }
      if (isExists) {
        saleUserOutcomes.push(structuredClone(saleUserOutcome));
      }
    });
  }

  if (!saleUserOutcomes.length) {
    const response = await httpService.post<ISaleUserOutcome[]>(
      commonConstants.apiURLs.company.getSaleUserByParams,
      body
    );
    saleUserOutcomes = response.data.data;
    saleUserOutcomes.forEach(saleUserOutcome => {
      cache.set(saleUserOutcome.id, structuredClone(saleUserOutcome));
    });
  }
  if (subscribe && saleUserOutcomes.length) {
    const saleUsersId = saleUserOutcomes.map(saleUserOutcome => saleUserOutcome.id);
    updaterService.subscribeForUpdates(saleUsersId, subscribe, isSingle);
  }

  return saleUserOutcomes;
};

export const saleUserOutcomeService = {
  getSaleUserOutcome,
  createSaleUserOutcome,
  updateSaleUserOutcome,
  getSaleUserOutcomeByParams,
};
