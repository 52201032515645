import auth0 from "auth0-js";

const webAuthOptions = {
  responseType: "token",
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  realm: process.env.REACT_APP_AUTH0_CONNECTION as string,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  connection: process.env.REACT_APP_AUTH0_CONNECTION as string,
};
const { domain, clientID, responseType } = webAuthOptions;
const webAuthSignIn = new auth0.WebAuth({
  domain,
  clientID,
  responseType,
  audience: "https://apigw.simetria.io",
});

const changePassword = (email: string): Promise<any> =>
  new Promise((resolve, reject) => {
    webAuthSignIn.changePassword(
      { connection: webAuthOptions.connection, email },
      (error, data) => {
        if (error) reject(error);
        if (data) resolve(data);
      }
    );
  });

const getJWT = () => {
  const token = localStorage.getItem("accessToken") || "";
  return `Bearer ${token}`;
};

export const authService = {
  getJWT,
  changePassword,
};
