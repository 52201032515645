import { ISaleDate } from "@simetria/models";
import * as React from "react";

const { useEffect, useState, useCallback } = React;

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const minuteInMiliseconds = 1000 * 60;
const hourInMiliseconds = 1000 * 60 * 60;
const dayInMiliseconds = 1000 * 60 * 60 * 24;
const saleUrgencyInDays = Number(process.env.REACT_APP_SALE_URGENCY_IN_DAYS);

const useCountdownTimer = (saleDates: ISaleDate[]) => {
  const [closestSaleDate, setClosestSaleDate] = useState<ISaleDate | null>(null);

  const getClosestSaleDate = useCallback((): ISaleDate | null => {
    if (closestSaleDate === null) {
      const nextClosestSaleDate = saleDates.find(saleDate => {
        return saleDate.dateValue! > Date.now();
      });
      if (nextClosestSaleDate) {
        return nextClosestSaleDate;
      }

      return null;
    }

    if (closestSaleDate?.dateValue! <= Date.now()) {
      const closestSaleIndex = saleDates.findIndex(saleDate => saleDate.id === closestSaleDate?.id);
      const nextClosestSaleDate = saleDates.find((saleDate, index) => {
        return index > closestSaleIndex && saleDate.dateValue! > Date.now();
      });
      if (nextClosestSaleDate) {
        return nextClosestSaleDate;
      }
      return null;
    } else {
      return { ...closestSaleDate! };
    }
  }, [closestSaleDate, saleDates]);

  useEffect(() => {
    const interval = setInterval(() => {
      setClosestSaleDate(getClosestSaleDate());
    }, 1000);

    return () => clearInterval(interval);
  }, [getClosestSaleDate]);

  return getReturnValues(closestSaleDate);
};

const getReturnValues = (saleDate: ISaleDate | null) => {
  let isUrgent: boolean = false;
  const timeLeft: TimeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (saleDate?.dateValue) {
    const saleDateValue = saleDate.dateValue;

    timeLeft.days = Math.floor((saleDateValue - Date.now()) / dayInMiliseconds);
    timeLeft.hours = Math.floor(
      ((saleDateValue - Date.now()) % dayInMiliseconds) / hourInMiliseconds
    );
    timeLeft.minutes = Math.floor(
      ((saleDateValue - Date.now()) % hourInMiliseconds) / minuteInMiliseconds
    );
    timeLeft.seconds = Math.floor(((saleDateValue - Date.now()) % minuteInMiliseconds) / 1000);

    isUrgent = timeLeft.days < saleUrgencyInDays;
  }

  return { timeLeft, saleDate, isUrgent };
};

export { useCountdownTimer };
