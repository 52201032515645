import { Stack, Typography, Box, Link } from "@mui/material";
import { createStyles } from "../../styles/utils";
import stopWatchSrc from "../../styles/assets/stopWatch.svg";
import { AppHeader } from "../../components/AppHeader/AppHeader";
const useStyles = createStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  content: {
    alignItems: "center",
    textAlign: "center",
    maxWidth: "800px",
  },
  icon: {
    width: "95px",
  },
  title: {
    height: "100%",
    overflow: "hidden",
  },
  customerSupport: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export const WaitingForApproval = () => {
  const styles = useStyles();

  return (
    <Stack sx={styles.container}>
      <AppHeader />
      <Box sx={styles.contentWrapper}>
        <Stack sx={styles.content}>
          <Box component="img" src={stopWatchSrc} sx={styles.icon} pb={8} />
          <Typography variant="h4" pb={5}>
            Account Verification in Progress
          </Typography>
          <Typography variant="subtitle3" pb={5}>
            Thank you for signing up!
            <br />
            We are currently verifying your account details. You will be able to log in and access
            the platform as soon as the verification process is completed.
          </Typography>
          <Link
            variant="subtitle3"
            pb={5}
            href="https://geehawumvn1.typeform.com/to/nex4aePr"
            target="_blank"
            rel="noreferrer"
          >
            Please fill this questionnaire (required)
          </Link>
          <Typography variant="subtitle3">
            If you have any urgent questions or concerns, please don't hesitate to contact our
            <Box sx={styles.customerSupport}>customer support team</Box>
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
