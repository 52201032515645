export const COMMON_RESOURCES = {
    en: {
        ok: "Ok",
        back: "Back",
        cancel: "Cancel",
        close: "Close",
        continue: "Continue",
        saleDates: {
            expirationDate: "Expiration date",
            recordDateForParticipation: "Record date for participation",
            sellingAmountDeterminationDate: "Selling amount determination date",
            sellingStartDate: "Selling start date",
            settlementDate: "Settlement date",
        },
    }
};