import { useCallback, useEffect, useState } from "react";

import { OffersGridRow } from "../types";
import { assetService, offerService } from "../../../services";
import { OfferStatusEnum, Asset } from "../../../types";

export const useOffersGridData = () => {
  const [gridData, setGridData] = useState<OffersGridRow[]>([]);
  const [allAssets, setAllAssets] = useState<Asset[]>([]);

  const fetchData = useCallback(async () => {
    const assets = (await assetService.getAllAssets()).data;
    const offers = (await offerService.getOffersByParams({ statuses: [OfferStatusEnum.approved] }))
      .data;

    const idToAssetMap = new Map(assets.map(asset => [asset.id, asset]));

    const gridData = offers.map(offer => {
      const asset = idToAssetMap.get(offer.assetId);
      return { asset, offer };
    });
    setGridData(gridData);
    setAllAssets(assets);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { gridData, fetchData, allAssets };
};
