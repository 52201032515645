import { pathnames } from "./pathnames";
import { ISideMenuItemsConfig } from "../types/side-menu-items.interface";

import { ReactComponent as UserIcon } from "../assets/icons/user_side.svg";
import { ReactComponent as DollarIcon } from "../assets/icons/dollar_icon.svg";
import { ReactComponent as MyHoldingsIcon } from "../assets/icons/my_holdings.svg";
import { ReactComponent as DataRoomIcon } from "../assets/icons/data_room_side.svg";
import { ReactComponent as MyDocumentsIcon } from "../assets/icons/documents_icon.svg";
import { ReactComponent as ReviewSignIcon } from "../assets/icons/review_sign_side.svg";
import { ReactComponent as SearchOverviewIcon } from "../assets/icons/search_overview_side.svg";

const {
  employee: { saleOffer, reviewAndSign },
  common: { sales, profile, overview, dataRoom, myHoldings, myDocuments },
} = pathnames;

export const sideMenuItems: ISideMenuItemsConfig = {
  company: {
    above: [
      {
        icon: DollarIcon,
        pageLabel: "Sales",
        pageUrl: sales,
      },
    ],
    bottom: [],
  },

  trustee: {
    above: [
      {
        icon: SearchOverviewIcon,
        pageLabel: "Overview",
        pageUrl: overview,
      },
      {
        icon: ReviewSignIcon,
        pageLabel: "Review & SIgn",
        pageUrl: reviewAndSign,
      },
      {
        icon: UserIcon,
        pageLabel: "Profile",
        pageUrl: profile,
      },
      {
        icon: MyDocumentsIcon,
        pageLabel: "My Documents",
        pageUrl: myDocuments,
      },
      {
        icon: DataRoomIcon,
        pageLabel: "Data Room",
        pageUrl: dataRoom,
      },
      {
        icon: DollarIcon,
        pageLabel: "Sales",
        pageUrl: sales,
      },
      {
        icon: MyHoldingsIcon,
        pageLabel: "My Holding",
        pageUrl: myHoldings,
      },
    ],
    bottom: [],
  },

  employee: {
    above: [
      {
        icon: SearchOverviewIcon,
        pageLabel: "Overview",
        pageUrl: overview,
      },
      {
        icon: ReviewSignIcon,
        pageLabel: "Review & SIgn",
        pageUrl: reviewAndSign,
      },
      {
        icon: UserIcon,
        pageLabel: "Profile",
        pageUrl: profile,
      },
      {
        icon: MyDocumentsIcon,
        pageLabel: "My Documents",
        pageUrl: myDocuments,
      },
      {
        icon: DataRoomIcon,
        pageLabel: "Data Room",
        pageUrl: dataRoom,
      },
      {
        icon: DollarIcon,
        pageLabel: "Sales",
        pageUrl: sales,
      },
      {
        icon: MyHoldingsIcon,
        pageLabel: "My Holding",
        pageUrl: myHoldings,
      },
      {
        icon: DollarIcon,
        pageLabel: "Sale Offer",
        pageUrl: saleOffer,
      },
    ],
    bottom: [],
  },

  investor: {
    above: [
      {
        icon: SearchOverviewIcon,
        pageLabel: "Overview",
        pageUrl: overview,
      },
      {
        icon: ReviewSignIcon,
        pageLabel: "Review & SIgn",
        pageUrl: reviewAndSign,
      },
      {
        icon: UserIcon,
        pageLabel: "Profile",
        pageUrl: profile,
      },
      {
        icon: MyDocumentsIcon,
        pageLabel: "My Documents",
        pageUrl: myDocuments,
      },
      {
        icon: DataRoomIcon,
        pageLabel: "Data Room",
        pageUrl: dataRoom,
      },
      {
        icon: DollarIcon,
        pageLabel: "Sales",
        pageUrl: sales,
      },
      {
        icon: MyHoldingsIcon,
        pageLabel: "My Holding",
        pageUrl: myHoldings,
      },
    ],
    bottom: [],
  },
};
