import * as React from "react";
import { ToastMessage } from "primereact/toast";

import { IUIContext } from "./types/ui-context.interface";

const { useState, createContext, useCallback } = React;

export const UIContext = createContext({} as IUIContext);

export const UiProvider = ({ children }: React.PropsWithChildren<{}>) => {
  // Toaster
  const [toasterMessage, setToasterMessage] = useState<ToastMessage>({});

  // Spinner
  const [spinnerCounter, setSpinnerCounter] = useState<number>(0);

  const showSpinner = useCallback(() => {
    setSpinnerCounter(prevCount => {
      return prevCount + 1;
    });
  }, []);

  const hideSpinner = useCallback(() => {
    setSpinnerCounter(prevCount => {
      let newCount = prevCount;
      if (newCount > 0) {
        newCount = newCount - 1;
      }

      return newCount;
    });
  }, []);

  const hideSpinnerForced = useCallback(() => {
    setSpinnerCounter(0);
  }, []);

  return (
    <UIContext.Provider
      value={{
        spinner: { spinnerCounter, showSpinner, hideSpinner, hideSpinnerForced },
        toaster: { toasterMessage, setToasterMessage },
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
