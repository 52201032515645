import { Components, Theme } from '@mui/material';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: {
    body: {
      height: "100vh",
      width: "100vw",
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  },
};
