import * as React from "react";
import { Outlet } from "react-router-dom";

import { SimetriaSpinner } from "../simetria-spinner/simetria-spinner";

import { IDeal } from "@simetria/models";
import { useUser } from "../../hooks";
import { dealService } from "../../services/deal.service";
import { INavigationManagerProps } from "./types/navigation-manager.interface";

import styles from "./navigation-manager.module.scss";

const { useState, useEffect } = React;

export const NavigationManager = ({ useNavigation }: INavigationManagerProps): JSX.Element => {
  const [deal, setDeal] = useState<IDeal>();
  const [renderInitialNavigation, setRenderInitialNavigation] = useState<boolean>(true);

  const { user } = useUser();
  const userId = user?.id;

  const { onBack, onContinue, navigateToActiveStep } = useNavigation();

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        if (userId) {
          const getDealResponse = await dealService.getDealByParams({ userIds: [userId] });
          setDeal(getDealResponse?.[0]);
        }
      } catch (error) {}
    };

    fetchDeal();
  }, [userId]);

  useEffect(() => {
    // ⚠️ Check must be explicit
    const listingStep = deal?.listingStep;
    if (listingStep !== undefined && renderInitialNavigation) {
      setRenderInitialNavigation(prevState => !prevState);
      navigateToActiveStep?.(listingStep);
    }
  }, [navigateToActiveStep, deal, renderInitialNavigation]);

  return (
    <div className={styles.wrapper}>
      {renderInitialNavigation ? (
        <div className={styles.spinnerWrapper}>
          <SimetriaSpinner />
        </div>
      ) : (
        <Outlet context={{ onBack, onContinue }} />
      )}
    </div>
  );
};
