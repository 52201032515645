import { DataGrid as MuixDataGrid, DataGridProps as MuixDataGridProps } from "@mui/x-data-grid";

const ROW_HEIGHT = 60;
const HEADER_HEIGHT = 60;

interface DataGridProps extends MuixDataGridProps {
  gridActions?: any;
}

export const DataGrid = ({ components, gridActions, ...rest }: DataGridProps) => {
  return (
    <MuixDataGrid
      disableColumnMenu
      hideFooterPagination
      pageSizeOptions={[]}
      rowSelection={false}
      rowHeight={ROW_HEIGHT}
      hideFooterSelectedRowCount
      columnHeaderHeight={HEADER_HEIGHT}
      components={{
        Toolbar: gridActions,
        ...components,
      }}
      {...rest}
    />
  );
};
