import axios from "axios";

import {IApiResponse} from "@simetria/common";
// import { authService } from "./auth.service";

const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    // Authorization: authService.getJWT(),
  };
};

const get = <T>(url: string) => {
  const headers = getHeaders();
  return axios.get<IApiResponse<T>>(url, { headers });
};

const post = <T>(url: string, body: any) => {
  const headers = getHeaders();
  return axios.post<IApiResponse<T>>(url, body, { headers });
};

const put = <T>(url: string, body: any) => {
  const headers = getHeaders();
  return axios.put<IApiResponse<T>>(url, body, { headers });
};

const HTTPDelete = <T>(url: string, body?: any) => {
  const headers = getHeaders();
  return axios.delete<IApiResponse<T>>(url, { headers });
};

export const httpService = {
  get,
  put,
  post,
  delete: HTTPDelete,
};
