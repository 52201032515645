import { Components, Theme } from "@mui/material";

import { primaryP10 } from "../colors";

export const MuiFilledInput: Components<Theme>["MuiFilledInput"] = {
  styleOverrides: {
    root: {
      "&, &:hover, &:focus, &.Mui-focused, &.Mui-disabled": {
        backgroundColor: "white",

        ".MuiFilledInput-input": {
          backgroundColor: primaryP10,
        },
      },
    },
    input: {
      marginTop: "20px",
      backgroundColor: primaryP10,
      paddingTop: 8,
    },
  },
};
