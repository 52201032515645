export const primaryP80 = "#150CCD"; // P80
export const primaryP10 = "#F3F2F8"; // P10
export const primaryP50 = "#6975ff"; // P50
export const primary = "#3344FF"; // P60
export const secondary = "#34396F"; // S100

export const textPrimary = "#263238";
export const textSecondary = "#546E7A";
export const textDisabled = "#B7C6CD";

export const blueBorder = "#C2C5E2";

export const buttonGreen = "#14A485";
export const buttonRed = "#E11308";

export const grey5 = "#F6F6F6";

export const red20 = "#FCE8E7";
export const red60 = "#B10B05";

export const green10 = "#E8F0E4";
export const green60 = "#2F6B5E";
