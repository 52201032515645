import { Settings } from "../types";
import { httpService } from "./http.service";

const apiUrl = process.env.REACT_APP_API_URL;

const getSettings = async () => {
  const apiResponse = await httpService.get<Settings>(`https://getsettings${apiUrl}`);
  return apiResponse.data.data;
};

export const SettingService = { getSettings };
