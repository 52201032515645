import { httpService } from "./http.service";
import { apiURLs } from "../constants/api-urls";
import { IGetBidByParamsRequestBody, IBid } from "@simetria/models";

const cache: Map<string, IBid> = new Map();

const getBid = async (bidId: string) => {
  let cachedBid = cache.get(bidId);
  if (cachedBid) {
    return cachedBid;
  }
  const response = await httpService.get<IBid>(apiURLs.common.bidBaseUrl(bidId));
  const bid = response.data.data;
  cache.set(bid.id, structuredClone(bid));
  return bid;
};

const getBidByParams = async (requestBody: IGetBidByParamsRequestBody) => {
  let cachedBid = null;
  for (const [, bid] of cache) {
    if (bid.companyId === requestBody.companyId) {
      cachedBid = bid;
      break;
    }
  }
  if (cachedBid) {
    return cachedBid;
  }
  const response = await httpService.post<IBid[]>(apiURLs.common.getBidByParams, requestBody);
  const bids = response.data.data;
  bids.forEach(bid => {
    cache.set(bid.id, structuredClone(bid));
  });
  return bids;
};

export const bidService = { getBid, getBidByParams };
