import * as React from "react";
import { UserContext } from "../context/user.context";

export const useUser = () => {
  const userContext = React.useContext(UserContext);
  if (userContext === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return { user: userContext.user, setUser: userContext.setUser };
};
