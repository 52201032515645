import * as React from "react";
import { IUserProfile } from "@simetria/models";

import { IUserContext } from "./types/user.interface";

const { useState, createContext } = React;

export const UserContext = createContext({} as IUserContext);

export const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [user, setUser] = useState<IUserProfile>({});
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
