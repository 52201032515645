import { PropsWithChildren, ReactEventHandler, ChangeEventHandler } from "react";

import { useFormik } from "formik";
import { FormControlLabel, Stack, TextField, Typography } from "@mui/material";

import { Asset, ShareClassEnum, StructureTypeEnum } from "../../../../../types";
import { ICreateOfferForm } from "../../../types";
import { FormCheckbox } from "../../../../../components/FormCheckbox/FormCheckbox";
import { FormTextField } from "../../../../../components/FormTextField/FormTextField";
import { FormNumberField } from "../../../../../components/FormNumberField/FormNumberField";
import { FormAutocomplete } from "../../../../../components/FormAutocomplete/FormAutocomplete";
import formatNumber from "../helpers";

interface CreateAskFormProps {
  isBid: boolean;
  allAssets: Asset[];
  formik: ReturnType<typeof useFormik<ICreateOfferForm>>;
}

export const CreateOfferForm = ({ formik, isBid, allAssets }: CreateAskFormProps) => {
  const shareClassOptions = Object.values(ShareClassEnum);
  const structureOptions = Object.values(StructureTypeEnum);
  const companies = Array.from(new Set(allAssets.map(asset => asset?.name)));

  const onCompanySelected: ReactEventHandler<HTMLDivElement> = event => {
    const companyName = (event?.target as any).value;
    const asset = allAssets.find(asset => asset?.name === companyName);
    if (asset?.lastRoundValuation) {
      formik.setFieldValue("lastRoundValuation", formatNumber(asset.lastRoundValuation));
    } else {
      formik.setFieldValue("lastRoundValuation", "");
    }
  };

  const onStructureSelected: ReactEventHandler<HTMLDivElement> = event => {
    const structure = (event?.target as any).value;
    formik.setFieldValue("structure", structure);
  };
  const onShareClassSelected: ReactEventHandler<HTMLDivElement> = event => {
    const shareClass = (event?.target as any).value;

    formik.setFieldValue("shareClass", shareClass);
  };

  const onMinimumShareAmountChage: ChangeEventHandler<HTMLInputElement> = event => {
    const unforramtedValue = event.target.value.replaceAll(",", "");
    const unforramtedSharePrice = formik.values.requestedSharePrice;

    if (unforramtedValue && unforramtedSharePrice) {
      const offerAmountToSellFrom = Number(unforramtedValue) * Number(unforramtedSharePrice);
      formik.setFieldValue("offerAmountToSellFrom", offerAmountToSellFrom);
    }
  };

  const onMaximumShareAmountChage: ChangeEventHandler<HTMLInputElement> = event => {
    const unforramtedValue = event.target.value.replaceAll(",", "");
    const unforramtedSharePrice = formik.values.requestedSharePrice;

    if (unforramtedValue && unforramtedSharePrice) {
      const offerAmountToSellTo = Number(unforramtedValue) * Number(unforramtedSharePrice);
      formik.setFieldValue("offerAmountToSellTo", offerAmountToSellTo);
    }
  };

  const onMinimumOfferedAmountChage: ChangeEventHandler<HTMLInputElement> = event => {
    const unforramtedValue = event.target.value.replaceAll(/[,$]/g, "");
    const unforramtedSharePrice = formik.values.requestedSharePrice;

    if (unforramtedValue && unforramtedSharePrice) {
      const shareAmountFrom = Number(unforramtedValue) / Number(unforramtedSharePrice);
      formik.setFieldValue("shareAmountFrom", shareAmountFrom.toFixed(1));
    }
  };

  const onMaximumOfferedAmountChage: ChangeEventHandler<HTMLInputElement> = event => {
    const unforramtedValue = event.target.value.replaceAll(/[,$]/g, "");
    const unforramtedSharePrice = formik.values.requestedSharePrice;

    if (unforramtedValue && unforramtedSharePrice) {
      const shareAmountTo = Number(unforramtedValue) / Number(unforramtedSharePrice);
      formik.setFieldValue("shareAmountTo", shareAmountTo.toFixed(1));
    }
  };

  return (
    <Stack spacing={1}>
      <FormRow>
        <FormAutocomplete
          sx={{ width: "-webkit-fill-available" }} // workaround for the width, need to revisit
          name="companyName"
          options={companies}
          onSelect={onCompanySelected}
          isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
          tetxFieldProps={{
            required: true,
            label: "Company Name",
          }}
        />
        <FormTextField
          label="Last Round Valuation"
          name="lastRoundValuation"
          disabled
          fullWidth
          InputLabelProps={{ shrink: Boolean(formik.values?.lastRoundValuation) ?? false }}
        />
      </FormRow>
      <FormRow>
        <FormAutocomplete
          sx={{ width: "-webkit-fill-available" }} // workaround for the width, need to revisit
          name="structure"
          options={structureOptions}
          onSelect={onStructureSelected}
          isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
          tetxFieldProps={{
            required: true,
            label: "Structure",
          }}
        />

        <FormAutocomplete
          sx={{ width: "-webkit-fill-available" }} // workaround for the width, need to revisit
          name="shareClass"
          options={shareClassOptions}
          onSelect={onShareClassSelected}
          isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
          tetxFieldProps={{
            required: true,
            label: "Share Class",
          }}
        />
      </FormRow>
      <Typography pt={6}>*Please provide share price or valuation.</Typography>
      <FormRow>
        <FormNumberField
          label="Requested Share Price"
          name="requestedSharePrice"
          fullWidth
          prefix="$"
        />
        <FormNumberField
          label="Requested Valuation (Estimated)"
          name="requestedValuation"
          fullWidth
          prefix="$"
        />
      </FormRow>
      <Typography>*Please provide shares amount or offered amount.</Typography>
      <FormRow>
        <FormNumberField
          onChange={onMinimumShareAmountChage}
          label="Shares Amount - From"
          name="shareAmountFrom"
          fullWidth
        />
        <FormNumberField
          onChange={onMaximumShareAmountChage}
          label="Shares Amount - To"
          name="shareAmountTo"
          fullWidth
        />
      </FormRow>
      <FormRow>
        <FormNumberField
          onChange={onMinimumOfferedAmountChage}
          label={`Offered Amount To ${isBid ? "Buy" : "Sell"} - From`}
          name="offerAmountToSellFrom"
          prefix="$"
          fullWidth
        />
        <FormNumberField
          onChange={onMaximumOfferedAmountChage}
          label={`Offered Amount To ${isBid ? "Buy" : "Sell"} - To`}
          name="offerAmountToSellTo"
          prefix="$"
          fullWidth
        />
      </FormRow>
      <Typography variant="body3">Blocks</Typography>
      <FormRow>
        <TextField label="Blocks" fullWidth disabled />
        <TextField label="Amount Per Block" fullWidth disabled />
      </FormRow>
      <FormRow>
        <FormControlLabel
          sx={{ pt: 4 }}
          control={<FormCheckbox name="termsAndConditions" value={true} />}
          label="I have read, understood and agreed to Ask submitting Terms & Conditions"
        />
      </FormRow>
    </Stack>
  );
};

const FormRow = ({ children }: PropsWithChildren) => {
  return (
    <Stack direction="row" spacing={4}>
      {children}
    </Stack>
  );
};
