import { ChangeEvent } from "react";

import { useGridApiContext } from "@mui/x-data-grid";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { ReactComponent as SearchIcon } from "../../../styles/assets/search.svg";

export const GridSearch = (props: TextFieldProps) => {
  const { current: gridApi } = useGridApiContext();

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    gridApi.setQuickFilterValues([value]);
    props.onChange?.(e);
  };

  return (
    <TextField
      placeholder="search"
      size="small"
      sx={{
        width: 500,
      }}
      variant="outlined"
      {...props}
      InputProps={{ startAdornment: <SearchIcon />, ...props.InputProps }}
      onChange={onChangeHandler}
    />
  );
};
