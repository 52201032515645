import { Components, Theme } from "@mui/material";

import { textSecondary } from "../colors";

export const MuiInputLabel: Components<Theme>["MuiInputLabel"] = {
  styleOverrides: {
    root: {
      fontSize: "14px",
    },
    filled: {
      transform: "translate(12px, 29px) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0, 3px) scale(0.85)",
        fontFamily: "PoppinsSemiBold",
      },
      "&.Mui-focused": {
        color: textSecondary
      },
    },
  },
};
