import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/fonts/poppins.scss";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/user.context";
import { Auth0Provider } from "@auth0/auth0-react";
import { theme } from "./styles/theme";
import { CssBaseline } from "@mui/material";
import { SettingsProvider } from "./context/settings.context";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SettingsProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </SettingsProvider>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
