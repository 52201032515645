import { GridRenderCellParams } from "@mui/x-data-grid";

import { OffersGridRow } from "../../../pages/Marketplace/types";

export const CreationDateCellRenderer = ({ row }: GridRenderCellParams<OffersGridRow>) => {
  const dateCreatedInMS = row.offer.creationDate || 0;
  const date = new Date(dateCreatedInMS);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formmatedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;

  return formmatedDate;
};
