import * as Yup from "yup";

export const getInitialValues = () => ({
  companyName: "",
  structure: "",
  shareClass: "",
  lastRoundValuation: undefined,
  offerAmountToSellTo: "",
  shareAmountTo: undefined,
  offerAmountToSellFrom: "",
  termsAndConditions: false,
  shareAmountFrom: undefined,
  requestedValuation: undefined,
  requestedSharePrice: undefined,
});

export const createAskValidationSchema = Yup.object({
  requestedValuation: Yup.number(),
  requestedSharePrice: Yup.number().test("valuationOrSharePrice", "", function () {
    const { requestedValuation, requestedSharePrice } = this.parent;
    return Boolean(requestedValuation || requestedSharePrice);
  }),
  shareAmountTo: Yup.string().test("numberOfshareOrOfferAmount", "", function () {
    const { shareAmountFrom, shareAmountTo, offerAmountToSellFrom, offerAmountToSellTo } =
      this.parent;

    return Boolean(
      (shareAmountFrom !== undefined && shareAmountTo !== undefined) ||
        (offerAmountToSellFrom !== undefined && offerAmountToSellTo !== undefined)
    );
  }),
  shareAmountFrom: Yup.string(),
  offerAmountToSellTo: Yup.string(),
  structure: Yup.string().required(),
  offerAmountToSellFrom: Yup.string(),
  shareClass: Yup.string().required(),
  companyName: Yup.string().required(),
  lastRoundValuation: Yup.string().notRequired(),
  termsAndConditions: Yup.boolean().required().oneOf([true]),
});

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
};

export default function formatNumber(num: number, precision = 1) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num.toString();
}

export const toEnumByString = <T extends object>(enumObj: T, value: string): T[keyof T] => {
  const enumValues = Object.values(enumObj);
  const result = enumValues.find(enumValue => enumValue === value);

  if (result === undefined) {
    throw new Error(`Cannot convert string "${value}" to enum.`);
  }

  return result;
};

export const calcOfferDiscount = (params: {
  lastRoundValuation?: number;
  requestedValuation?: number;
  lastRoundSharePrice?: number;
  requestedSharePrice?: number;
}) => {
  const discount = { percentDiff: "", newValuation: "" };
  const { lastRoundSharePrice, lastRoundValuation, requestedSharePrice, requestedValuation } =
    params;

  if (lastRoundValuation && requestedValuation) {
    const newValuationPercent = (100 * requestedValuation) / lastRoundValuation;
    if (newValuationPercent - 100 > 0) {
      discount.percentDiff = "+" + (newValuationPercent - 100).toFixed(1) + "%";
    } else {
      discount.percentDiff = (newValuationPercent - 100).toFixed(1) + "%";
    }
    discount.newValuation = "$" + formatNumber(requestedValuation);
  } else if (lastRoundSharePrice && requestedSharePrice) {
    const newValuationPercent = (100 * requestedSharePrice) / lastRoundSharePrice;
    if (newValuationPercent - 100 > 0) {
      discount.percentDiff = "+" + (newValuationPercent - 100).toFixed(1) + "%";
    } else {
      discount.percentDiff = (newValuationPercent - 100).toFixed(1) + "%";
    }

    if (lastRoundValuation) {
      discount.newValuation = "$" + formatNumber(lastRoundValuation);
    }
  }

  return discount;
};
