import { Asset, Offer, OfferTypeEnum } from "../../types";
import { DialogProps } from "../../components/Dialog/Dialog";

export interface OffersGridRow {
  offer: Offer;
  asset?: Asset;
}

export interface CreateOfferDialogPropos {
  allAssets: Asset[];
  offerType: OfferTypeEnum;
  open: DialogProps["open"];
  fetchData: () => Promise<void>;
  handleClose: DialogProps["handleClose"];
}

export interface ICreateOfferForm {
  structure: string;
  shareClass: string;
  companyName: string;
  shareAmountTo?: number;
  shareAmountFrom?: number;
  lastRoundValuation?: string;
  requestedValuation?: number;
  termsAndConditions: boolean;
  requestedSharePrice?: number;
  offerAmountToSellTo?: string;
  offerAmountToSellFrom?: string;
}

export enum CreateOfferStepsEnum {
  Create,
  Summary,
}

export interface OfferSummaryProps {
  formValues: ICreateOfferForm;
  isBid: Boolean;
}
