import { send, init } from "@emailjs/browser";

const initialize = () => {
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  if (publicKey) {
    init(publicKey);
  }
};

const sendEmail = (params: {
  title?: string;
  message?: string;
  templateId: string;
  senderEmail: string;
  senderName?: string;
  receiverEmail: string;
}) => {
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const { title, senderEmail, templateId, message, senderName, receiverEmail } = params;

  const receiverName = receiverEmail.split("@")?.[0];

  const templateParams: Record<string, any> = {
    title: title,
    message: message,
    senderName: senderName,
    senderEmail: senderEmail,
    receiverName: receiverName,
    receiverEmail: receiverEmail,
  };

  if (serviceId && templateId && publicKey) {
    send(serviceId, templateId, templateParams, publicKey);
  }
};

export const emailService = { sendEmail, initialize };
