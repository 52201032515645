import { captureException as sentryCaptureException } from "@sentry/browser";

import { apiURLs } from "../constants";
import { httpService } from "./http.service";
import { firebaseService } from "./firebase.service";
import { ObserveToken } from "./types/token.interface";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { ITransaction, IGetTransactionByParams } from "@simetria/models";

const isLoadingCache: Map<string, boolean> = new Map();
const transactionCache: Map<string, ITransaction> = new Map();

const transactionUpdaterService = new UpdaterService<ITransaction>(transactionCache);
const isLoadingUpdaterService = new UpdaterService<boolean>(isLoadingCache);

const createTransaction = async (newTransaction: Partial<ITransaction>) => {
  const createTransactionResponse = await httpService.post<ITransaction>(
    apiURLs.common.createTransaction,
    newTransaction
  );
  const createdTransaction = createTransactionResponse.data.data;

  transactionCache.set(createdTransaction.id, structuredClone(createdTransaction));
  transactionUpdaterService.notifySubscribers([createdTransaction.id]);

  return createdTransaction;
};

const getTransactionIsLoadingById = (
  transactionId: string,
  subscribe?: ReactSetterFunction
): boolean => {
  let transactionIsLoading = isLoadingCache.get(transactionId);

  if (transactionIsLoading === null || transactionIsLoading === undefined) {
    isLoadingCache.set(transactionId, false);
  }
  if (subscribe) {
    isLoadingUpdaterService.subscribeForUpdates(transactionId, subscribe, true);
  }
  return Boolean(transactionIsLoading);
};

const updateTransactionIsLoadingById = (transactionId: string, transactionIsLoading: boolean) => {
  isLoadingCache.set(transactionId, transactionIsLoading);

  isLoadingUpdaterService.notifySubscribers([transactionId]);
};

const getTransactionByParams = async (
  body: IGetTransactionByParams,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend = false
) => {
  let transactionList: ITransaction[] = [];
  const { buyerUserIdList, sellerUserIdList, status, tokenIdList } = body;

  if (!getFromBackend) {
    const isStatusExists = Boolean(status);
    const isTokenIdListExists = Boolean(tokenIdList?.length);
    const isBuyerUserIdListExists = Boolean(buyerUserIdList?.length);
    const isSellerUserIdListExists = Boolean(sellerUserIdList?.length);

    transactionCache.forEach(transaction => {
      let isExists = false;

      if (isStatusExists && isExists) {
        isExists = Boolean(transaction.status === status);
      }

      if (isTokenIdListExists && isExists) {
        isExists = Boolean(transaction.status === status);
      }

      if (isBuyerUserIdListExists && isExists && transaction.buyerUserId) {
        isExists = Boolean(buyerUserIdList?.includes(transaction.buyerUserId));
      }

      if (isSellerUserIdListExists && isExists && transaction.sellerUserId) {
        isExists = Boolean(sellerUserIdList?.includes(transaction.sellerUserId));
      }

      if (isStatusExists && isExists) {
        isExists = Boolean(transaction.status === status);
      }

      if (isExists) {
        transactionList.push(structuredClone(transaction));
      }
    });
  }

  if (!transactionList.length || getFromBackend) {
    const response = await httpService.post<ITransaction[]>(
      apiURLs.common.getTransactionByParams,
      body
    );
    transactionList = response.data.data;
    transactionList.forEach(transaction => {
      transactionCache.set(transaction.id, structuredClone(transaction));
    });
  }
  if (subscribe && transactionList.length) {
    const transactionIdsList = transactionList.map(transaction => transaction.id);
    transactionUpdaterService.subscribeForUpdates(transactionIdsList, subscribe, isSingle);
  }

  return transactionList;
};

const updateTransaction = async (newTransaction: ITransaction) => {
  let updatedTransaction = transactionCache.get(newTransaction.id);
  try {
    updateTransactionIsLoadingById(newTransaction.id, true);

    const response = await httpService.put<ITransaction>(
      apiURLs.common.updateTransaction(newTransaction.id),
      newTransaction
    );

    updatedTransaction = response.data.data;
    transactionCache.set(updatedTransaction.id, structuredClone(updatedTransaction));

    transactionUpdaterService.notifySubscribers([updatedTransaction.id]);

    updateTransactionIsLoadingById(newTransaction.id, false);
  } catch (error) {
    updateTransactionIsLoadingById(newTransaction.id, false);
    sentryCaptureException(`[transaction-service] updateTransaction => error:${error}`);
  }

  return updatedTransaction;
};

const updateCachedTransaction = (transactionId: string, transaction: Partial<ITransaction>) => {
  const cachedTransaction = transactionCache.get(transactionId);
  if (cachedTransaction) {
    const updatedTransaction = { ...cachedTransaction, ...transaction };
    transactionCache.set(transactionId, updatedTransaction);
    transactionUpdaterService.notifySubscribers([transactionId]);
  }
};

const deleteTransaction = async (transactionId: string) => {
  try {
    await httpService.delete(apiURLs.common.deleteTransaction(transactionId));
    const cachedTransaction = transactionCache.get(transactionId);
    if (cachedTransaction) {
      transactionCache.delete(transactionId);
    }
  } catch (error) {
    sentryCaptureException(`[transaction-service] deleteTransaction => error:${error}`);
  }
};

export const transactionService = {
  createTransaction,
  deleteTransaction,
  updateTransaction,
  getTransactionByParams,
  updateCachedTransaction,
  getTransactionIsLoadingById,
  updateTransactionIsLoadingById,
};
