export interface GetUserByAuthIdRequestBody {
  authId: string;
  email?: string;
}

export enum UserStatusEnum {
  approved = "approved",
  notApproved = "notApproved",
  waitingForApproval = "waitingForApproval",
}

export enum UserTypeEnum {
  broker = "Broker",
  investor = "Investor",
  exchangeFund = "Exchange Fund",
}

export interface User {
  id: string;
  authId?: string;
  lastName?: string;
  workEmail: string;
  firstName?: string;
  dateOfBirth?: number;
  type?: UserTypeEnum;
  status: UserStatusEnum;
  personalEmail?: string;
  emailVerified: boolean;
  onboardingStep?: string;
  userProfilePIIId: string;
}
