import { httpService } from "./http.service";
import { commonConstants } from "@simetria/common";
import { firebaseService } from "./firebase.service";
import { IGetCompanySaleRequestBody } from "./types/sale.interface";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { ISale, ISaleKitStatus, ISaleParticipationData } from "@simetria/models";

interface ICreateSale extends Omit<ISale, "id"> {}

const cache: Map<string, ISale> = new Map();

const updaterService = new UpdaterService<ISale>(cache);

const getCompanySale = async (
  body: IGetCompanySaleRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend: boolean = false
) => {
  let sale = null;
  for (const [, cachedSale] of cache) {
    if (cachedSale.id === body.saleId && cachedSale.companyId === body.companyId) {
      sale = structuredClone(cachedSale);
      break;
    }
  }
  if (!sale || getFromBackend) {
    const response = await httpService.get<ISale>(
      commonConstants.apiURLs.common.companySale(body.companyId, body.saleId)
    );
    sale = response.data.data;
    cache.set(sale.id, structuredClone(sale));
  }
  if (subscribe) {
    updaterService.subscribeForUpdates(sale.id, subscribe, isSingle);
  }
  return sale;
};

const getAllCompanySales = async (
  companyId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let companySales: ISale[] = [];
  cache.forEach(sale => {
    if (sale.companyId === companyId) {
      companySales.push(structuredClone(sale));
    }
  });
  if (!companySales.length) {
    const response = await httpService.get<ISale[]>(
      commonConstants.apiURLs.common.companySales(companyId)
    );
    companySales = response.data.data;
    companySales.forEach(sale => {
      cache.set(sale.id, structuredClone(sale));
    });
  }
  if (subscribe) {
    const companiesSaleId = companySales.map(sale => sale.id);
    updaterService.subscribeForUpdates(companiesSaleId, subscribe, isSingle);
  }
  return companySales;
};

const getSalesByIds = async (
  saleIds: string[],
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let sales: ISale[] = [];
  cache.forEach(sale => {
    if (saleIds.includes(sale.id)) {
      sales.push(structuredClone(sale));
    }
  });
  if (!sales.length) {
    const reponse = await httpService.post<ISale[]>(
      commonConstants.apiURLs.common.companySaleGetMany,
      {
        entityIds: saleIds,
      }
    );
    sales = reponse.data.data;
    sales.forEach(sale => {
      cache.set(sale.id, structuredClone(sale));
    });
  }
  if (subscribe) {
    const salesId = sales.map(sale => sale.id);
    updaterService.subscribeForUpdates(salesId, subscribe, isSingle);
  }
  return sales;
};

const createSale = async (sale: ICreateSale) => {
  const response = await httpService.post<ISale>(
    commonConstants.apiURLs.common.companySales(sale.companyId),
    sale
  );
  const newSale = response.data.data;
  cache.set(newSale.id, structuredClone(newSale));
  return newSale;
};

const updateSale = async (sale: ISale) => {
  const response = await httpService.put<ISale>(
    commonConstants.apiURLs.common.companySale(sale.companyId, sale.id),
    sale
  );
  const updatedSale = response.data.data;
  cache.set(updatedSale.id, structuredClone(updatedSale));
  updaterService.notifySubscribers([updatedSale.id]);
  return updatedSale;
};

const updateSaleParticipationSettings = async (
  companyId: string,
  saleParicipationData: ISaleParticipationData
) => {
  const response = await httpService.post<ISale>(
    commonConstants.apiURLs.common.updateSaleParticipationSettings(companyId),
    saleParicipationData
  );
  const updatedSale = response.data.data;
  cache.set(updatedSale.id, structuredClone(updatedSale));
  return updatedSale;
};

const prepareSaleKitInput = async (saleId: string) => {
  const response = await httpService.post<ISale>(
    commonConstants.apiURLs.common.prepareSaleKit(saleId),
    { saleId }
  );

  const updatedSale = response.data.data;
  cache.set(updatedSale.id, updatedSale);

  updaterService.notifySubscribers([saleId]);

  return updatedSale;
};

const observeSaleKits = (
  companyId: string,
  saleId: string,
  callback: (saleKitStatus: ISaleKitStatus) => void
) => {
  return firebaseService.observeSaleKits(companyId, saleId, updatedSaleKit(callback));
};

const updatedSaleKit = (callback: (saleKitStatus: ISaleKitStatus) => void) => {
  return (saleKitStatus: ISaleKitStatus) => {
    callback(saleKitStatus);
  };
};

export const saleService = {
  createSale,
  updateSale,
  getSalesByIds,
  getCompanySale,
  observeSaleKits,
  getAllCompanySales,
  prepareSaleKitInput,
  updateSaleParticipationSettings,
};
