import { httpService } from "./http.service";
import { pathnames } from "../constants/pathnames";
import { errorHandlingService } from "./error-handling.service";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { captureException as sentryCaptureException } from "@sentry/browser";
import { IUserBankAccountDetails, StatusCodeEnum } from "@simetria/models";

const { bankAccountDetails } = pathnames.common;

const bankDetailsCache: Map<string, IUserBankAccountDetails> = new Map();
const isLoadingCache: Map<string, boolean> = new Map();

const isLoadingUpdaterService = new UpdaterService<boolean>(isLoadingCache);
const bankDetailsUpdaterService = new UpdaterService<IUserBankAccountDetails>(bankDetailsCache);

const getBankDetailsIsLoadingById = (userId: string, subscribe?: ReactSetterFunction): boolean => {
  let bankDetailsIsLoading = isLoadingCache.get(userId);

  if (bankDetailsIsLoading === null || bankDetailsIsLoading === undefined) {
    isLoadingCache.set(userId, false);
  }
  if (subscribe) {
    isLoadingUpdaterService.subscribeForUpdates(userId, subscribe, true);
  }
  return Boolean(bankDetailsIsLoading);
};

const updateBankDetailsIsLoadingById = async (userId: string, bankDetailsIsLoading: boolean) => {
  await isLoadingCache.set(userId, bankDetailsIsLoading);

  isLoadingUpdaterService.notifySubscribers([userId]);
};

const getBankDetails = async (
  userId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let bankDetails = structuredClone(bankDetailsCache.get(userId));
  if (!bankDetails) {
    try {
      const response = await httpService.get<IUserBankAccountDetails>(bankAccountDetails(userId));
      bankDetails = response.data.data;
      bankDetailsCache.set(userId, structuredClone(bankDetails));
    } catch (error) {
      if (
        !(
          errorHandlingService.isAxiosError(error) &&
          error.response?.status === StatusCodeEnum.NOT_FOUND
        )
      ) {
        sentryCaptureException(
          `[userBankDetailsService] getBankDetails => error: ${JSON.stringify(error)}`
        );
      }
    }
  }
  if (subscribe) {
    bankDetailsUpdaterService.subscribeForUpdates(userId, subscribe, isSingle);
  }
  return bankDetails;
};

const createBankDetails = async (userId: string, body: Partial<IUserBankAccountDetails>) => {
  updateBankDetailsIsLoadingById(userId, true);

  const response = await httpService.post<IUserBankAccountDetails>(
    bankAccountDetails(body.userId as string),
    body
  );
  const newBankDetails = response.data.data;
  bankDetailsCache.set(newBankDetails.userId, structuredClone(newBankDetails));
  bankDetailsUpdaterService.notifySubscribers([newBankDetails.userId]);

  updateBankDetailsIsLoadingById(userId, false);
  return newBankDetails;
};

const updateBankDetails = async (body: IUserBankAccountDetails) => {
  let updateBankDetails = bankDetailsCache.get(body.userId);
  try {
    updateBankDetailsIsLoadingById(body.userId, true);

    const response = await httpService.put<IUserBankAccountDetails>(
      bankAccountDetails(body.userId),
      body
    );
    updateBankDetails = response.data.data;
    bankDetailsCache.set(updateBankDetails.userId, structuredClone(updateBankDetails));
    bankDetailsUpdaterService.notifySubscribers([updateBankDetails.userId]);

    updateBankDetailsIsLoadingById(body.userId, false);
  } catch (error) {
    updateBankDetailsIsLoadingById(body.userId, false);
    sentryCaptureException(`[bank-details-service] updateBankDetails => error:${error}`);
  }

  return updateBankDetails;
};
export const userBankDetailsService = {
  getBankDetails,
  createBankDetails,
  updateBankDetails,
  getBankDetailsIsLoadingById,
};
