import { httpService } from "./http.service";
import { apiURLs } from "../constants/api-urls";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { IDocumentTemplate, IGetDocumentTemplateByParamsRequestBody } from "@simetria/models";

const cache: Map<string, IDocumentTemplate> = new Map();

const updaterService = new UpdaterService<IDocumentTemplate>(cache);

export const getDocumentTemplatesByParams = async (
  body: IGetDocumentTemplateByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  const isDocumentIdsExist = Boolean(body.documentTemplatesIds?.length);
  const isNameListExist = Boolean(body.nameList?.length);
  let documentsTemplates: IDocumentTemplate[] = [];

  cache.forEach(template => {
    const templateId = template.id;
    const templateName = template.name;
    let isExists = false;
    if (isDocumentIdsExist) {
      isExists = Boolean(body.documentTemplatesIds?.includes(templateId));
    }
    if (isExists && isNameListExist) {
      isExists = Boolean(body.nameList?.includes(templateName));
    }
    if (isExists) {
      documentsTemplates.push(structuredClone(template));
    }
  });
  if (!documentsTemplates.length) {
    const response = await httpService.post<IDocumentTemplate[]>(
      apiURLs.common.documentTemplateGetByParams,
      body
    );
    documentsTemplates = response.data.data;
    documentsTemplates.forEach(template => {
      cache.set(template.id, structuredClone(template));
    });
  }
  if (subscribe) {
    const documentTemplatesIds = documentsTemplates.map(template => template.id);
    updaterService.subscribeForUpdates(documentTemplatesIds, subscribe, isSingle);
  }

  return documentsTemplates;
};
export const getAllDocumentTemplates = async (
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let docTemplates: IDocumentTemplate[] = [];

  cache.forEach(docTemplate => {
    docTemplates.push(structuredClone(docTemplate));
  });

  if (!docTemplates.length) {
    const response = await httpService.get<IDocumentTemplate[]>(
      apiURLs.common.documentTemplateGetAll
    );
    docTemplates = response.data.data;
    docTemplates.forEach(template => {
      cache.set(template.id, structuredClone(template));
    });
  }
  if (subscribe) {
    const templatesIds = docTemplates.map(docTemplate => docTemplate.id);
    updaterService.subscribeForUpdates(templatesIds, subscribe, isSingle);
  }
  return docTemplates;
};

export const documentTemplateService = {
  getAllDocumentTemplates,
  getDocumentTemplatesByParams,
};
