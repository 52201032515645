import { ISaleKitStatus } from "@simetria/models";
import { ObserveSaleKits } from "./types/firebase.interface";
import { getApps, initializeApp, FirebaseOptions } from "firebase/app";
import { getDatabase, ref, set, onChildAdded } from "firebase/database";

const initializeFirebaseApp = (options: FirebaseOptions) => {
  initializeApp(options);
};

const isFirebaseInitialized = () => getApps().length !== 0;

const observeUserDocuments = (userId: string, callback: (data: any) => void) => {
  if (!isFirebaseInitialized()) {
    return;
  }
  const db = getDatabase();
  const dbPath = `users/${userId}/documents`;
  const dbRef = ref(db, dbPath);
  return onChildAdded(dbRef, async snapshot => {
    const val = snapshot.val();
    callback(val);
    // need to remove ther child after the update was completed, in order to not get another notification about the same document
    const childRef = ref(db, `${dbPath}/${val.userDocSignatureId}`);
    set(childRef, null);
  });
};

const observeSaleKits: ObserveSaleKits = (companyId, saleId, callback) => {
  if (!isFirebaseInitialized()) {
    return;
  }
  const db = getDatabase();
  let dbPath = `company/${companyId}/sale/${saleId}/sale-kit`;

  const dbRef = ref(db, dbPath);
  return onChildAdded(dbRef, async snapshot => {
    const val: { status: ISaleKitStatus } = snapshot.val();

    callback(val.status);

    const childRef = ref(db, dbPath);
    set(childRef, null);
  });
};

const observeSaleUsers = (userId: string, callback: (data: any) => void) => {
  if (!isFirebaseInitialized()) {
    return;
  }
  const db = getDatabase();
  const dbPath = `users/${userId}/sale-user`;
  const dbRef = ref(db, dbPath);
  return onChildAdded(dbRef, async snapshot => {
    const val = snapshot.val();
    callback(val);

    // need to remove ther child after the update was completed, in order to not get another notification about the same document
    const childRef = ref(db, `${dbPath}/${val.saleUserId}`);
    set(childRef, null);
  });
};

const observeTokens = (userId: string, callback: (data: any) => void) => {
  if (!isFirebaseInitialized()) {
    return;
  }
  const db = getDatabase();
  const dbPath = `user/${userId}/token`;
  const dbRef = ref(db, dbPath);
  return onChildAdded(dbRef, async snapshot => {
    const val = snapshot.val();
    callback(val);

    // need to remove ther child after the update was completed, in order to not get another notification about the same document
    const childRef = ref(db, `${dbPath}/${val}`);
    set(childRef, null);
  });
};

const observeUserAssets = (userId: string, callback: (data: any) => void) => {
  if (!isFirebaseInitialized()) {
    return;
  }
  const db = getDatabase();
  const dbPath = `users/${userId}/user-asset`;
  const dbRef = ref(db, dbPath);
  return onChildAdded(dbRef, async snapshot => {
    const val = snapshot.val();
    callback(val);

    // need to remove ther child after the update was completed, in order to not get another notification about the same document
    const childRef = ref(db, `${dbPath}/${val.userAssetId}`);
    set(childRef, null);
  });
};

export const firebaseService = {
  observeTokens,
  observeSaleKits,
  observeSaleUsers,
  observeUserAssets,
  observeUserDocuments,
  initializeFirebaseApp,
  isFirebaseInitialized,
};
