import clsx from "clsx";
import * as React from "react";
import { Dialog } from "primereact/dialog";

import { useUI } from "../../hooks";
import { pathnames } from "../../constants";
import { uiMessages } from "../../constants";
import { SimetriaSpinner } from "../simetria-spinner/simetria-spinner";

import { IDocumentDialogProps } from "./types/document-dialog.interface";

import styles from "./document-dialog.module.scss";

const { useEffect } = React;

const {
  common: { autoCloseIframe },
} = pathnames;
export const DocumentDialog = ({
  documentLink,
  onDialogHide,
  showDialog,
}: IDocumentDialogProps) => {
  const {
    toaster: { setToasterMessage },
  } = useUI();

  useEffect(() => {
    const closeIframeHandler = () => {
      setToasterMessage({
        severity: "info",
        detail: uiMessages.shareholder.userSignDocument.pendingSigning,
      });
      onDialogHide();
    };

    window.document.addEventListener("closeIframeEvent", closeIframeHandler, false);

    return () => {
      window.document.removeEventListener("closeIframeEvent", closeIframeHandler);
    };
  }, [onDialogHide, setToasterMessage]);

  const getRedirectUrl = (documentLink: string): string | undefined => {
    const redirectUri = window.origin + autoCloseIframe;

    if (documentLink) {
      return documentLink + `&redirect_uri=${redirectUri}`;
    }

    return documentLink;
  };

  const documentToSign: string | undefined = getRedirectUrl(documentLink);

  return (
    <Dialog
      visible={showDialog}
      className={"documentModal"}
      onHide={() => {
        onDialogHide();
      }}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.documentDisplay}>
          {documentToSign ? (
            <iframe
              frameBorder="0"
              id="documentIframe"
              src={documentToSign}
              title="documentToSign"
              className={styles.documentToSign}
            />
          ) : (
            <div
              className={clsx(
                "flex flex-column justify-content-evenly align-items-center",
                styles.documentToSign
              )}
            >
              <h3>Retrieving Your Document</h3>

              <SimetriaSpinner />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
