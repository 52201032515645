import { Dialog } from "../Dialog/Dialog";
import { Box } from "@mui/material";

import SuccessSrc from "../../styles/assets/success.svg";
import Typography from "@mui/material/Typography/Typography";

export interface ConnectDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

export const ConnectDialog = (props: ConnectDialogProps) => {
  const { closeDialog, isOpen } = props;

  return (
    <Dialog
      open={isOpen}
      header={<Box component="img" src={SuccessSrc}></Box>}
      title="Request Sent Successfully"
      content={
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="subtitle5">
            An email with your information and order details was sent.
            <br />
            Our team is now working on your request.
            <br />
            Expect further updates shortly.
          </Typography>
        </Box>
      }
      handleClose={closeDialog}
    />
  );
};
