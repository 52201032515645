import _ from "lodash";
import * as React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

import { ITooltipProps } from "./types/tooltip.interface";

import styles from "./tooltip.module.scss";
import "react-popper-tooltip/dist/styles.css";

const { memo } = React;

export const Tooltip = memo(
  ({
    trigger,
    children,
    placement,
    tooltipContent,
    ...popperOptions
  }: ITooltipProps): JSX.Element => {
    const { visible, setTooltipRef, setTriggerRef, getArrowProps, getTooltipProps } =
      usePopperTooltip({ trigger, placement, ...popperOptions });

    return (
      <>
        <span ref={setTriggerRef}>{children}</span>

        {visible && tooltipContent && (
          <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
            <div {...getArrowProps({ className: "tooltip-arrow" })} />

            <span className={styles.tooltipContent}>{tooltipContent}</span>
          </div>
        )}
      </>
    );
  },
  _.isEqual
);
