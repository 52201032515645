import { Components, Theme } from "@mui/material";

export const MuiFormControlLabel: Components<Theme>["MuiFormControlLabel"] = {
  styleOverrides: {
    label: {
      fontSize: "14px",
      lineHeight: "21px"
    },
  },
};
