import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { commonHooks } from "@simetria/common";

import { ReactComponent as LogOutIcon } from "@simetria/common/src/assets/icons/log_out.svg";
import { ReactComponent as MoreVerticalIcon } from "@simetria/common/src/assets/icons/more.svg";

const { useAuth } = commonHooks;

export const ActionsMenu = () => {
  const popupState = usePopupState({ variant: "popover" });
  const { logout } = useAuth();
  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <MoreVerticalIcon />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};
