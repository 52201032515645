import * as React from "react";

import { IModalConfig, IModalContextValues } from "./types/modal.interface";

const { useState, useCallback, createContext } = React;

export const ModalContext = createContext({} as IModalContextValues);

export const ModalContextProvider = ({ children }: any) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<IModalConfig>({
    size: "s",
    title: "",
    buttons: {},
    variant: "info",
    showHeader: true,
    isTextCenetered: true,
    description: undefined,
  });

  const updateModalConfig = useCallback((newConfig: IModalConfig) => {
    setShowModal(true);
    setModalConfig(prevState => ({ ...prevState, ...newConfig }));
  }, []);

  const initValues = {
    showModal,
    setShowModal,
    updateModalConfig,

    modalConfig,
    setModalConfig,
  };

  return <ModalContext.Provider value={initValues}>{children}</ModalContext.Provider>;
};
