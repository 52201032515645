import * as React from "react";

import { AutoComplete } from "../autocomplete/autocomplete";

import { IAutoCompleteFieldProps } from "../autocomplete/types/auto-complete.interface";

import styles from "./autocomplete-field.module.scss";

const { useState, useEffect } = React;

export const AutoCompleteField = ({
  name,
  formik,
  label,
  suggestions,
  ...rest
}: IAutoCompleteFieldProps): JSX.Element => {
  const [isResult, setIsResult] = useState<boolean>(false);

  useEffect(() => {
    if (
      (formik.values[name] > 1 && suggestions?.length < 1) ||
      (formik.values[name] > 1 && suggestions?.length < 1) ||
      formik?.values[name]?.length < 2
    ) {
      setIsResult(true);
    } else {
      setIsResult(false);
    }
  }, [name, suggestions, formik.values, suggestions?.length]);

  return (
    <div>
      <AutoComplete
        {...rest}
        name={name}
        label={label}
        suggestions={suggestions}
        value={formik.values[name]}
        onChange={(e: any) => {
          if (rest.onChange) {
            rest.onChange(e);
          }

          formik.handleChange(e);
        }}
      />

      {!isResult && formik.values[name].length > 0 && (
        <div className={styles.noCoincidenceBox}>
          No results found for ‘{formik.values[name]?.name || formik.values[name]}’. If you want us
          to add a missing company name please inform our{" "}
          <span className={styles.blueColor}>customer support</span>
        </div>
      )}
    </div>
  );
};
