import clsx from "clsx";
import { FileUpload as FileUploadPrime, FileUploadProps } from "primereact/fileupload";

import { useUI } from "../../hooks";
import { storage } from "../../constants/cloud-storage";

const { maxFileSize } = storage;

const maxFileSizeInMB = (Number(maxFileSize) / 1000000).toFixed(2);

interface IFileUploadProps extends FileUploadProps {
  label: string;
  isFileTypeUploaded?: boolean;
  uploadHandler: (payload: any) => void;
}

export const FileUpload = ({
  label,
  disabled,
  className,
  uploadHandler,
  isFileTypeUploaded = false,
  ...rest
}: IFileUploadProps) => {
  const {
    toaster: { setToasterMessage },
  } = useUI();
  return (
    <FileUploadPrime
      {...rest}
      auto
      onValidationFail={(file: File) => {
        let errorDetails: string = "Something went wront, please try again.";
        if (file.size > Number(maxFileSize)) {
          errorDetails = `${file.name} is too big, maximum file size is: ${maxFileSizeInMB} MB`;
        }

        setToasterMessage({
          severity: "error",
          detail: errorDetails,
        });
      }}
      customUpload
      name="uploadDocs"
      disabled={disabled}
      maxFileSize={+maxFileSize}
      uploadHandler={uploadHandler}
      accept="image/*, application/pdf"
      chooseOptions={{
        label,
        icon: isFileTypeUploaded ? "pi pi-check" : "pi pi-upload",
      }}
      className={clsx(className, disabled && "p-fileupload-disabled")}
    />
  );
};
