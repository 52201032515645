import { createTheme, Components } from "@mui/material/styles";

import * as componentsOverrides from "./overrides";
import {
  primary,
  secondary,
  buttonRed,
  buttonGreen,
  textPrimary,
  textDisabled,
  textSecondary,
} from "./colors";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 700,
      lg: 1200,
      xl: 1536,
    }
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
      disabled: textDisabled,
    },
    success: {
      main: buttonGreen,
    },
    error: {
      main: buttonRed,
    },
  },
  spacing: 4,
  typography: {
    fontFamily: "PoppinsRegular",
  },
  components: componentsOverrides as Components,
});
