import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { AppHeader } from "../AppHeader/AppHeader";
import { Marketplace } from "../../pages/Marketplace/Marketplace";

export const AppWrapper = () => {
  return (
    <Stack spacing={9} height="100%" overflow="hidden">
      <AppHeader />
      <Box pr={7} pl={7} flex={1} overflow="hidden">
        <Marketplace />
      </Box>
    </Stack>
  );
};
