import { blueBorder, grey5, textSecondary } from "../colors";

export const MuiDataGrid = {
  styleOverrides: {
    root: {
      border: "none",
    },
    columnHeader: {
      "&:focus, &:focus-within": {
        outline: "none",
      },
    },
    columnHeaderTitle: {
      fontSize: "16px",
      lineHeight: "24px",
      color: textSecondary,
      fontFamily: "PoppinsSemiBold",
    },
    columnSeparator: {
      display: "none",
    },
    withBorderColor: {
      borderColor: blueBorder,
    },
    footerContainer: {
      border: "none",
    },
    row: {
      "&.Mui-selected, &:hover": {
        background: grey5,
        "&:hover": {
          background: grey5,
        },
      },
    },
    cell: {
      border: "none",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: textSecondary,
      "&:focus, &:focus-within": {
        outline: "none",
      },
    },
  },
};
