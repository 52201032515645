import { Components, Theme } from "@mui/material";

import { blueBorder } from "../colors";

export const MuiMenu: Components<Theme>["MuiMenu"] = {
  styleOverrides: {
    paper: {
      border: `1px solid ${blueBorder}`,
      borderRadius: "4px",
    },
    list: {
      paddingTop: 4,
      paddingBottom: 4,
      fontSize: "14px",
    },
  },
};
