import { Components, Theme } from "@mui/material";

import { textSecondary } from "../colors";

export const MuiMenuItem: Components<Theme>["MuiMenuItem"] = {
  styleOverrides: {
    root: {
      fontSize: "14px",
      color: textSecondary
    },
  },
};
