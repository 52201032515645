import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { NumericFormatProps, NumberFormatValues, SourceInfo, NumericFormat } from 'react-number-format';

type FormTextFieldProps = {
  name: string;
} & NumericFormatProps<TextFieldProps>;

export const FormNumberField = ({ name, onValueChange, helperText, ...rest }: FormTextFieldProps) => {
  const [field, meta, helpers] = useField(name);

  const onValueChangeHandler = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    onValueChange?.(values, sourceInfo);
    helpers.setValue(values.floatValue, true);
  };

  const showError = meta.touched && Boolean(meta.error);

  return (
    <NumericFormat
      thousandSeparator
      name={name}
      error={showError}
      helperText={(showError && meta.error) || helperText || ' '}
      value={field.value}
      onBlur={field.onBlur}
      {...rest}
      onValueChange={onValueChangeHandler}
      customInput={TextField}
    />
  );
};
