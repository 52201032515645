import { Asset } from "../types";
import {httpService} from "./http.service";

const apiUrl = process.env.REACT_APP_API_URL;

const getAllAssets = async () => {
  const apiResponse = await httpService.get<Asset[]>(`https://getallassets${apiUrl}`);
  return apiResponse.data;
};

const getAssetById = async (id: string) => {
  const apiResponse = await httpService.get<Asset>(`https://getasset${apiUrl}?id=${id}`);
  return apiResponse.data;
};

export const assetService = {
  getAllAssets,
  getAssetById,
};
