import i18n, {Resource} from "i18next";
import { initReactI18next } from "react-i18next";
import { COMMON_RESOURCES } from "./common-translations";


i18n.use(initReactI18next)
.init({
    lng: "en",
    returnNull: false,
    react: {
        useSuspense: false
    },
    interpolation: {
        escapeValue: false
    }
})
.catch(err => {
    console.error("Initializing i18n falied:", err)
});

export function declareTranslations(namespace: string, translation: Resource) {
    Object.entries(translation).forEach(([language, resource]) => 
      i18n.addResourceBundle(language, namespace, resource)
    );
}

declareTranslations("common", COMMON_RESOURCES);

declare module 'i18next' {
    interface CustomTypeOptions {
      returnNull: false;
    }
}