import {
  IGetUserRoleByParamsRequestBody,
  ICreateUserRoleByParamsRequestBody,
} from "@simetria/models";
import { IUserRole } from "@simetria/models";
import { httpService } from "./http.service";
import { commonConstants } from "@simetria/common";
import { ReactSetterFunction, UpdaterService } from "./updater.service";

const cache: Map<string, IUserRole> = new Map();

const updaterService = new UpdaterService<IUserRole>(cache);

const getUserRoleByParams = async (
  body: IGetUserRoleByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend = false
) => {
  let userRoles: IUserRole[] = [];
  if (!getFromBackend) {
    const isTypeExists = Boolean(body.typeList?.length);
    const isUserIdExists = Boolean(body.userId);
    const isStatusExists = Boolean(body.status);
    const isCompanyIdExists = Boolean(body.companyId);
    cache.forEach(userRole => {
      let isValid = true;
      if (isTypeExists) {
        isValid = body.typeList!.includes(userRole.type);
      }
      if (isValid && isUserIdExists) {
        isValid = userRole.userId === body.userId;
      }
      if (isValid && isStatusExists) {
        isValid = userRole.status === body.status;
      }
      if (isValid && isCompanyIdExists) {
        isValid = userRole.companyId === body.companyId;
      }
      if (isValid) {
        userRoles.push(structuredClone(userRole));
      }
    });
  }

  if (!userRoles.length) {
    const response = await httpService.post<IUserRole[]>(
      commonConstants.apiURLs.common.getUserRoleByParams,
      body
    );
    userRoles = response.data.data;
    userRoles.forEach(userRole => cache.set(userRole.id, structuredClone(userRole)));
    if (subscribe) {
      const userRoleIds = userRoles.map(userRole => userRole.id);
      updaterService.subscribeForUpdates(userRoleIds, subscribe, isSingle);
    }
  }
  return userRoles;
};

const createUserRoleByParams = async (body: ICreateUserRoleByParamsRequestBody) => {
  const response = await httpService.post<IUserRole[]>(
    commonConstants.apiURLs.common.createUserRoleByParams,
    body
  );

  const userRoles = response.data.data;
  const userRoleIds = userRoles.map(userRole => userRole.id);
  userRoles.forEach(userRole => cache.set(userRole.id, structuredClone(userRole)));
  updaterService.notifySubscribers(userRoleIds);
  return userRoles;
};

export const userRoleService = { getUserRoleByParams, createUserRoleByParams };
