import { httpService } from "./http.service";
import { commonConstants } from "@simetria/common";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { IUserGrant, IGetUserGrantByParamsRequestBody } from "@simetria/models";

const cache: Map<string, IUserGrant> = new Map();

const updaterService = new UpdaterService<IUserGrant>(cache);

const getUserGrant = async (
  grantId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let userGrant = structuredClone(cache.get(grantId));
  if (!userGrant) {
    const response = await httpService.get<IUserGrant>(
      commonConstants.apiURLs.common.userGrantBaseUrl(grantId)
    );
    userGrant = response.data.data;
    cache.set(grantId, structuredClone(userGrant));
  }
  if (subscribe) {
    updaterService.subscribeForUpdates(grantId, subscribe, isSingle);
  }
  return userGrant;
};

const getUserGrantsByParams = async (
  body: IGetUserGrantByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  const { userIds } = body;
  let userGrants: IUserGrant[] = [];
  if (userIds?.length) {
    cache.forEach(userGrant => {
      if (userIds.includes(userGrant.userId as string)) {
        userGrants.push(structuredClone(userGrant));
      }
    });
  }
  if (!userGrants.length) {
    const response = await httpService.post<IUserGrant[]>(
      commonConstants.apiURLs.common.getUserGrantByParams,
      body
    );
    userGrants = response.data.data;
    userGrants.forEach(userGrant => {
      cache.set(userGrant.id as string, structuredClone(userGrant));
    });
  }
  if (subscribe) {
    const userGrantsIds = userGrants.map(userGrants => userGrants.id as string);
    updaterService.subscribeForUpdates(userGrantsIds, subscribe, isSingle);
  }
  return userGrants;
};

const createUserGrant = async (body: IUserGrant) => {
  const response = await httpService.post<IUserGrant>(
    commonConstants.apiURLs.common.createUserGrant,
    body
  );

  const userGrant = response.data.data;
  cache.set(userGrant.id as string, structuredClone(userGrant));
  updaterService.notifySubscribers([userGrant.id as string]);
  return userGrant;
};

const updateUserGrant = async (body: IUserGrant) => {
  const response = await httpService.put<IUserGrant>(
    commonConstants.apiURLs.common.userGrantBaseUrl(body.id as string),
    body
  );

  const userGrant = response.data.data;
  cache.set(userGrant.id as string, structuredClone(userGrant));
  updaterService.notifySubscribers([userGrant.id as string]);
  return userGrant;
};

const deleteUserGrant = async (userGrantId: string) => {
  const response = await httpService.delete<IUserGrant>(
    commonConstants.apiURLs.common.userGrantBaseUrl(userGrantId)
  );
  cache.delete(userGrantId);

  return response.data.data;
};

export const userGrantsService = {
  getUserGrant,
  deleteUserGrant,
  updateUserGrant,
  createUserGrant,
  getUserGrantsByParams,
};
