import { useField } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';
import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

type AutocompleteProps = Omit<MuiAutocompleteProps<any, any, any, any>, 'renderInput'>;

interface FormAutocompleteProps extends AutocompleteProps {
  name: string;
  tetxFieldProps: TextFieldProps;
}

export const FormAutocomplete = ({ name, tetxFieldProps, ...rest }: FormAutocompleteProps) => {
  const [field, , helpers] = useField(name);
  return (
    <Autocomplete
      {...field}
      onChange={(_, value) => helpers.setValue(value)}
      {...rest}
      renderInput={(params) => <TextField {...params} {...tetxFieldProps} name={name} />}
    />
  );
};
