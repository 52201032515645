declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    subtitle3: React.CSSProperties;
    subtitle5: React.CSSProperties;
    subtitle4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    subtitle5?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body5: true;
    body6: true;
    subtitle3: true;
    subtitle5: true;
    subtitle4: true;
  }
}

export const MuiTypography = {
  styleOverrides: {
    h3: {
      fontSize: "28px",
      lineHeight: "42px",
      fontFamily: "PoppinsBold",
    },
    body3: {
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "PoppinsSemiBold",
    },
    body5: {
      fontSize: "14px",
      lineHeight: "21px",
      fontFamily: "PoppinsMedium",
    },
    body6: {
      fontSize: "14px",
      lineHeight: "21px",
    },
    h4: {
      fontSize: "22px",
      lineHeight: "30px",
      fontFamily: "PoppinsBold",
    },
    subtitle3: {
      fontSize: "18px",
      lineHeight: "27px",
      fontFamily: "PoppinsLight",
    },
    subtitle5: {
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "PoppinsLight",
    },
    subtitle4: {
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "PoppinsMedium"
    }
  },
};
