import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { OffersGridRow } from "../../../pages/Marketplace/types";
import formatNumber, {
  calcOfferDiscount,
} from "../../../pages/Marketplace/dialogs/CreateOfferDialog/helpers";

export const DiscountCellRenderer = ({ row }: GridRenderCellParams<OffersGridRow>) => {
  const { offer, asset } = row;

  const { percentDiff } = calcOfferDiscount({
    requestedValuation: offer?.valuation,
    requestedSharePrice: offer?.sharePrice,
    lastRoundValuation: asset?.lastRoundValuation,
    lastRoundSharePrice: asset?.lastRoundSharePrice,
  });

  let valuationAndSharePrice = "";
  let lastRoundSharePriceText = "";
  let lastRoundValuationText = "";

  if (asset?.lastRoundSharePrice) {
    lastRoundSharePriceText = `$${asset.lastRoundSharePrice}`;
  }

  if (asset?.lastRoundValuation) {
    lastRoundValuationText = `$${formatNumber(asset.lastRoundValuation)} Val`;
    if (lastRoundSharePriceText) {
      lastRoundValuationText = " / " + lastRoundValuationText;
    }
  }

  valuationAndSharePrice = lastRoundSharePriceText + lastRoundValuationText;

  if (lastRoundSharePriceText && lastRoundValuationText) {
    valuationAndSharePrice = `(${valuationAndSharePrice})`;
  }

  if (percentDiff) {
    return (
      <>
        {percentDiff} <Box component={"br"} /> {valuationAndSharePrice}
      </>
    );
  } else {
    return <>{valuationAndSharePrice}</>;
  }
};
