import * as React from "react";
import { SettingsContext } from "../context/settings.context";

export const useSettings = () => {
  const settingsContext = React.useContext(SettingsContext);
  if (settingsContext === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return { settings: settingsContext.settings, setSettings: settingsContext.setSettings };
};
