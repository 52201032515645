import { useField } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";

type FormTextFieldProps = {
  name: string;
} & TextFieldProps;

export const FormTextField = ({ name, ...rest }: FormTextFieldProps) => {
  const [field, meta] = useField(name);
  const showError = meta.touched && Boolean(meta.error);
  return (
    <TextField
      {...rest}
      {...field}
      error={showError}
      helperText={(showError && meta.error) || " "}
    />
  );
};
