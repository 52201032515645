import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { User } from "../../types";
import { useUser } from "../../hooks";
import { ActionsMenu } from "../ActionsMenu/ActionsMenu";
import { ReactComponent as Logo } from "../../styles/assets/logo.svg";

const getUserName = (user: User) => {
  const { lastName, firstName } = user;
  const userName = `${firstName} ${lastName?.charAt(0) || ""}***`;
  return userName;
};

export const AppHeader = () => {
  const { user } = useUser();

  const userName = getUserName(user);

  return (
    <AppBar position="static">
      <Toolbar>
        <Logo style={{ marginBottom: "4px" }} />
        <Box pl={11} flex={1}>
          {/* <Typography color="text.primary" variant="body3">
            Marketplace
          </Typography> */
          /*Need to return if we want routes */}
        </Box>
        <Box>
          <Typography color="text.primary" variant="body6" pr={4}>
            {userName}
          </Typography>
          <ActionsMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
