// ⚠️ Children paths must be without leading foreward slash, otherwise RRD will break!

export const pathnames = {
  common: {
    home: "/",
    sales: "/sales",
    signIn: "/sign-in",
    signUp: "/sign-up",
    profile: "/profile",
    loading: "/loading",
    sale: "/sale/:saleId",
    overview: "/overview",
    dataRoom: "/data-room",
    saleOffer: "/sale-offer",
    onboarding: "/onboarding",
    myHoldings: "/my-holdings",
    myDocuments: "/my-documents",
    accessDenied: "/access-denied",
    userSale: "/user-sale/:saleId",
    reenterEmail: "/reenter-email",
    forgotPassword: "/forgot-password",
    changePassword: "/change-password",
    deletedAccount: "/deleted-account",
    contactSupport: "/contact-support",
    setNewPassword: "/set-new-password",
    getManyUsers: "/user/get-by-params",
    autoCloseIframe: "/auto-close-iframe",
    emailVerification: "/email-verification",
    googleAuthentication: "/google-authorize",
    profileBankDetails: "/profile/bank-details",
    changeCurrentPassword: "/change-current-password",
    profilePersonalDetails: "/profile/personal-details",
    bankAccountDetails: (authOld: string) => `/user/${authOld}/bank-account-details`,

    children: {
      onboarding: {
        onboardingKyc: "kyc",
        onboardingBankDetails: "bank-details",
        onboardingPersonalInformation: "personal-information",
      },

      profile: {
        profileBankDetails: "bank-details",
      },
    },
  },

  company: {
    sales: "/sales",

    participantDetails: "/participant-details/:saleId",
    participantDetailsBaseUrl: (saleId: string, userId: string, saleUserId: string) =>
      `/participant-details/${saleUserId}?userId=${userId}&saleId=${saleId}`,

    editParticipantsSale: "/sale/participants/:saleId",
    companySale: (saleId: string) => `/sale/${saleId}`,
    companySales: (saleId: string) => `/sales?companyId=${saleId}`,
    companySaleSettings: (saleId: string) => `/sale/participants/${saleId}`,
  },

  employee: {
    saleOffer: "/sale-offer",
    reviewAndSign: "/review-and-sign",
  },

  shareholder: {
    dealListing: {
      dealListingBase: "/deal-listing",
      children: {
        welcome: "welcome",
        personalDetails: "personal-details",
        equityInfo: {
          equityInfoBase: "equity-information",
          children: {
            companySelection: "company-selection",
            types: "types",
            shares: "shares",
            options: "options",
            summary: "summary",
          },
        },
      },
    },

    saleOnboarding: {
      signing: "signing",
      welcome: "welcome",
      saleBaseUrl: "/sale",
      saleSummary: "sale-summary",
      generalInfo: "general-info",
      bankDetails: "bank-details",
      personalDetails: "personal-details",
      participationAmount: "participation-amount",
    },

    saleSummary: "/sale-summary",
    saleSummaryBaseUrl: (saleId: string) => `/sale-summary?saleId=${saleId}`,

    saleOutcome: "/sale-outcome",
    saleOutcomeBaseUrl: (saleId: string) => `/sale-outcome?saleId=${saleId}`,
  },

  investor: {
    marketplace: "/marketplace",
    personalDetails: "/personal-details",
    certificationOnboarding: "/certification-onboarding",
  },

  simetria: "http://simetria.io",
};
