import { httpService } from "./http.service";
import { apiURLs } from "../constants/api-urls";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import { ISaleDate, IGetSaleDateByParamsRequestBody } from "@simetria/models";

const cache: Map<string, ISaleDate> = new Map();

const updaterService = new UpdaterService<ISaleDate>(cache);

const getSaleDate = async (
  saleDateId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false
) => {
  let saleDate = structuredClone(cache.get(saleDateId));
  if (!saleDate) {
    const response = await httpService.get<ISaleDate>(apiURLs.common.saleDateBaseUrl(saleDateId));
    saleDate = response.data.data;
    cache.set(saleDate.id, structuredClone(saleDate));
  }
  if (subscribe) {
    updaterService.subscribeForUpdates(saleDateId, subscribe, isSingle);
  }
  return saleDate;
};

const createSaleDate = async (body: Partial<ISaleDate>) => {
  const response = await httpService.post<ISaleDate>(apiURLs.common.createSaleDate, body);
  const createdSaleDate = response.data.data;
  cache.set(createdSaleDate.id, createdSaleDate);
  return createdSaleDate;
};

const updateSaleDate = async (body: ISaleDate) => {
  const response = await httpService.put<ISaleDate>(
    apiURLs.common.saleDateBaseUrl(body.id as string),
    body
  );
  const updatedSaleDate = response.data.data;
  cache.set(updatedSaleDate.id, structuredClone(updatedSaleDate));
  updaterService.notifySubscribers([updatedSaleDate.id]);
  return updatedSaleDate;
};

const createOrUpdateSaleDates = async (saleDateDtoList: Omit<ISaleDate, "id">[]) => {
  const response = await httpService.post<ISaleDate[]>(apiURLs.common.createOrUpdateSaleDate, {
    saleDateList: saleDateDtoList,
  });
  const saleDatesData = response.data.data;
  saleDatesData.forEach(saleDate => {
    cache.set(saleDate.id, saleDate);
  });

  return saleDatesData;
};

const deleteSaleDate = async (saleDateId: string) => {
  const response = await httpService.delete(apiURLs.common.saleDateBaseUrl(saleDateId));

  cache.delete(saleDateId);

  return response.data.data;
};

const getSaleDatesByParams = async (
  body: IGetSaleDateByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend = false
) => {
  const { saleIds } = body;
  let saleDates: ISaleDate[] = [];

  if (!getFromBackend) {
    if (saleIds?.length) {
      cache.forEach(saleDate => {
        if (saleIds.includes(saleDate.saleId as string)) {
          saleDates.push(structuredClone(saleDate));
        }
      });
    }
  }

  if (!saleDates.length) {
    const response = await httpService.post<ISaleDate[]>(apiURLs.common.getSaleDateByParams, body);
    saleDates = response.data.data;
    saleDates.forEach(saleDate => cache.set(saleDate.id, structuredClone(saleDate)));
  }
  if (subscribe) {
    const saleDatesId = saleDates.map(saleDate => saleDate.id);
    updaterService.subscribeForUpdates(saleDatesId, subscribe, isSingle);
  }
  return saleDates;
};

export const saleDateService = {
  getSaleDate,
  deleteSaleDate,
  updateSaleDate,
  createSaleDate,
  getSaleDatesByParams,
  createOrUpdateSaleDates,
};
