import { httpService } from "./http.service";
import { apiURLs } from "../constants/api-urls";
import { UpdaterService } from "./updater.service";
import { IDeal, IGetDealByParamsRequestBody } from "@simetria/models";

const cache: Map<string, IDeal> = new Map();

const updaterService = new UpdaterService<IDeal>(cache);

const getDeal = async (
  dealId: string,
  subscribe?: React.Dispatch<React.SetStateAction<any>>,
  isSingle: boolean = false
) => {
  let deal = structuredClone(cache.get(dealId));
  if (!deal) {
    const response = await httpService.get<IDeal>(apiURLs.common.dealBaseUrl(dealId));
    const deal = response.data.data;
    cache.set(dealId, structuredClone(deal));
  }
  if (subscribe) {
    updaterService.subscribeForUpdates(dealId, subscribe, isSingle);
  }
  return deal;
};

const getDealByParams = async (
  requestBody: IGetDealByParamsRequestBody,
  subscribe?: React.Dispatch<React.SetStateAction<any>>,
  isSingle: boolean = false
) => {
  const isStatusExists = Boolean(requestBody.status);
  const isUserIdsExists = Boolean(requestBody.userIds);
  const isCompanyIdExists = Boolean(requestBody.companyId);
  let deals: IDeal[] = [];
  cache.forEach(deal => {
    let isValid = true;
    if (isStatusExists) {
      isValid = deal.status === requestBody.status;
    }
    if (isValid && isUserIdsExists) {
      isValid = Boolean(requestBody.userIds?.includes(deal.userId as string));
    }
    if (isValid && isCompanyIdExists) {
      isValid = deal.companyId === requestBody.companyId;
    }
    if (isValid) {
      deals.push(structuredClone(deal));
    }
  });
  if (!deals.length) {
    const response = await httpService.post<IDeal[]>(apiURLs.common.getDealByParams, requestBody);
    deals = response.data.data;
    cache.forEach(deal => cache.set(deal.id, structuredClone(deal)));
  }
  if (subscribe) {
    const dealsId = deals.map(deal => deal.id);
    updaterService.subscribeForUpdates(dealsId, subscribe, isSingle);
  }
  return deals;
};

const createDeal = async (deal: Partial<IDeal>) => {
  const response = await httpService.post<IDeal>(apiURLs.common.createDeal, deal);
  const createdDeal = response.data.data;
  cache.set(createdDeal.id, structuredClone(createdDeal));
  updaterService.notifySubscribers([createdDeal.id]);
  return createdDeal;
};

const updateDeal = async (deal: Partial<IDeal>) => {
  const response = await httpService.put<IDeal>(
    apiURLs.common.dealBaseUrl(deal.id as string),
    deal
  );
  const updatedDeal = response.data.data;
  cache.set(updatedDeal.id, structuredClone(updatedDeal));
  updaterService.notifySubscribers([updatedDeal.id]);
  return updatedDeal;
};

export const dealService = {
  getDeal,
  createDeal,
  updateDeal,
  getDealByParams,
};
