import { captureException as sentryCaptureException } from "@sentry/browser";

import {
  ISaleUser,
  ICompareObjectsFlatParams,
  IUpdateSaleUserRequestBody,
  INDAStatusChangedNotification,
  IGetSaleUserByParamsRequestBody,
} from "@simetria/models";
import { httpService } from "./http.service";
import { compareObjectsFlat } from "../utils";
import { commonConstants } from "@simetria/common";
import { firebaseService } from "./firebase.service";
import { ICreateSale } from "../types/create-sale.interface";
import { ObserveSaleUser } from "./types/sale-user.interface";
import { ReactSetterFunction, UpdaterService } from "./updater.service";

const isLoadingCache: Map<string, boolean> = new Map();
const saleUserCache: Map<string, ISaleUser> = new Map();

const saleUserUpdaterService = new UpdaterService<ISaleUser>(saleUserCache);
const isLoadingUpdaterService = new UpdaterService<boolean>(isLoadingCache);

const getSaleUserIsLoadingById = (saleUserId: string, subscribe?: ReactSetterFunction): boolean => {
  let saleUserIsLoading = isLoadingCache.get(saleUserId);

  if (saleUserIsLoading === null || saleUserIsLoading === undefined) {
    isLoadingCache.set(saleUserId, false);
  }
  if (subscribe) {
    isLoadingUpdaterService.subscribeForUpdates(saleUserId, subscribe, true);
  }
  return Boolean(saleUserIsLoading);
};

const updateSaleUserIsLoadingById = async (saleUserId: string, saleUserIsLoading: boolean) => {
  await isLoadingCache.set(saleUserId, saleUserIsLoading);

  isLoadingUpdaterService.notifySubscribers([saleUserId]);
};

const getSaleUser = async (
  saleUserId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend: boolean = false
): Promise<ISaleUser> => {
  let saleUser = structuredClone(saleUserCache.get(saleUserId));
  if (!saleUser || getFromBackend) {
    const response = await httpService.get<ISaleUser>(
      commonConstants.apiURLs.common.saleUseBaseUrl(saleUserId)
    );
    saleUser = response.data.data;
    saleUserCache.set(saleUser.id, saleUser);
  }
  if (subscribe) {
    saleUserUpdaterService.subscribeForUpdates(saleUserId, subscribe, isSingle);
  }
  return saleUser;
};

const createUpdateSaleUsers = async (sales: ICreateSale[]) => {
  const response = await httpService.post<ISaleUser[]>(
    commonConstants.apiURLs.company.createOrUpdateUserSales,
    {
      saleUsers: sales,
    }
  );
  const newSaleUsers = response.data.data;
  newSaleUsers.forEach(newSaleUser => {
    saleUserCache.set(newSaleUser.id, newSaleUser);
  });
  const newSaleUserIds = newSaleUsers.map(saleUser => saleUser.id);
  saleUserUpdaterService.notifySubscribers(newSaleUserIds);
  return newSaleUsers;
};

const getSalesUserByParams = async (
  body: IGetSaleUserByParamsRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend = false
) => {
  let saleUsers: ISaleUser[] = [];

  if (!getFromBackend) {
    const isUserIdsExists = Boolean(body.userIds?.length);
    const isSaleIdsExists = Boolean(body.saleIds?.length);
    const isStatusesExists = Boolean(body.statuses?.length);

    saleUserCache.forEach(saleUser => {
      const saleId = saleUser.saleId;
      const userId = saleUser.userId;
      const status = saleUser.status;

      let isExists = false;
      if (isUserIdsExists && isSaleIdsExists) {
        isExists = Boolean(body.userIds?.includes(userId) && body.saleIds?.includes(saleId));
      } else if (isUserIdsExists) {
        isExists = Boolean(body.userIds?.includes(userId));
      } else if (isSaleIdsExists) {
        isExists = Boolean(body.saleIds?.includes(saleId));
      }
      if (isStatusesExists) {
        isExists = Boolean(body.statuses?.includes(status));
      }

      if (isExists) {
        saleUsers.push(structuredClone(saleUser));
      }
    });
  }

  if (!saleUsers.length) {
    const response = await httpService.post<ISaleUser[]>(
      commonConstants.apiURLs.company.getSaleUserByParams,
      body
    );
    saleUsers = response.data.data;
    saleUsers.forEach(saleUser => {
      saleUserCache.set(saleUser.id, structuredClone(saleUser));
    });
  }
  if (subscribe && saleUsers.length) {
    const saleUsersId = saleUsers.map(saleUser => saleUser.id);
    saleUserUpdaterService.subscribeForUpdates(saleUsersId, subscribe, isSingle);
  }

  return saleUsers;
};

const updateSaleUser = async (params: {
  newSaleUser: ISaleUser;
  entities?: ICompareObjectsFlatParams;
}) => {
  const { entities } = params;
  let updatedSaleUser = saleUserCache.get(params.newSaleUser.id);
  try {
    let requestBody: IUpdateSaleUserRequestBody = { newSaleUser: params.newSaleUser };
    updateSaleUserIsLoadingById(params.newSaleUser.id, true);

    if (entities?.objectA && entities?.objectB && entities?.objectsType) {
      let changedEntityAndProperties: Record<string, string[]> = {};
      const changedProperties = compareObjectsFlat(entities.objectA, entities.objectB);
      if (changedProperties.length) {
        changedEntityAndProperties[entities.objectsType] = changedProperties;
        requestBody.changedEntityAndProperties = changedEntityAndProperties;
      }
    }

    const response = await httpService.put<ISaleUser>(
      commonConstants.apiURLs.common.updateSaleUserUrl(params.newSaleUser.id),
      requestBody
    );

    updatedSaleUser = response.data.data;
    saleUserCache.set(updatedSaleUser.id, structuredClone(updatedSaleUser));

    saleUserUpdaterService.notifySubscribers([updatedSaleUser.id]);

    updateSaleUserIsLoadingById(params.newSaleUser.id, false);
  } catch (error) {
    updateSaleUserIsLoadingById(params.newSaleUser.id, false);
    sentryCaptureException(`[sale-user-service] updateSaleUser => error:${error}`);
  }

  return updatedSaleUser;
};

const updateCachedSaleUser = (saleUserId: string, saleUser: Partial<ISaleUser>) => {
  const cachedSaleUser = saleUserCache.get(saleUserId);
  if (cachedSaleUser) {
    const updatedSaleUser = { ...cachedSaleUser, ...saleUser };
    saleUserCache.set(saleUserId, updatedSaleUser);
    saleUserUpdaterService.notifySubscribers([saleUserId]);
  }
};

const onNDAStatusChanged = (callback: () => void) => {
  return async (NDANotification: INDAStatusChangedNotification) => {
    const { ndaSigned, saleUserId } = NDANotification;
    const BDSaleUser = await getSaleUser(saleUserId, undefined, false, true);
    saleUserCache.set(saleUserId, { ...BDSaleUser, ndaSigned: ndaSigned });
    saleUserUpdaterService.notifySubscribers([saleUserId]);

    if (ndaSigned) {
      callback(); // toaster messege that says the document was signed
    }
  };
};

const observeSaleUsers: ObserveSaleUser = (userId, callback) => {
  return firebaseService.observeSaleUsers(userId, onNDAStatusChanged(callback));
};

export const saleUserService = {
  getSaleUser,
  updateSaleUser,
  observeSaleUsers,
  updateCachedSaleUser,
  getSalesUserByParams,
  createUpdateSaleUsers,
  getSaleUserIsLoadingById,
};
