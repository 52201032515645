import { useCallback, useState } from 'react';

export const useDialog = (): [boolean, () => void, () => void] => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = useCallback(() => setIsDialogOpen(true), []);

  const closeDialog = useCallback(() => setIsDialogOpen(false), []);

  return [isDialogOpen, openDialog, closeDialog];
};
