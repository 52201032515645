import * as React from "react";
import { Settings } from "../types";

export interface ISettingsContext {
  settings: Settings;
  setSettings: React.Dispatch<React.SetStateAction<Settings>>;
}

const { useState, createContext } = React;

export const SettingsContext = createContext({} as ISettingsContext);

export const SettingsProvider = ({ children }: React.PropsWithChildren) => {
  const [settings, setSettings] = useState<Settings>({} as Settings);
  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
