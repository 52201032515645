import { useMemo } from "react";

import { GridColDef } from "@mui/x-data-grid";

import {
  TypeCellRenderer,
  PriceCellRenderer,
  ActionsCellRenderer,
  DiscountCellRenderer,
  CompanyNameCellRenderer,
  CreationDateCellRenderer,
} from "../../../components/DataGrid/renderers";
import { OffersGridRow } from "../types";

export const useColumns = () => {
  return useMemo<GridColDef<OffersGridRow>[]>(
    () => [
      {
        field: "offer.structure",
        flex: 1,
        headerName: "Structure",
        valueGetter: ({ row }) => row.offer.structure,
      },
      {
        field: "asset.name",
        flex: 1,
        minWidth: 250,
        headerName: "Company",
        renderCell: CompanyNameCellRenderer,
        valueGetter: ({ row }) => row.asset?.name,
      },
      {
        field: "asset.sector",
        flex: 1,
        headerName: "Sector",
        valueGetter: ({ row }) => row.asset?.sector,
      },
      { field: "offer.type", flex: 1, headerName: "Type", renderCell: TypeCellRenderer },
      {
        field: "offer.size",
        flex: 1,
        headerName: "Size",
        valueGetter: ({ row }) => row.offer.size,
      },
      {
        field: "offer.sharePrice",
        flex: 1,
        headerName: "Price",
        renderCell: PriceCellRenderer,
      },
      {
        field: "offer.userType",
        flex: 1,
        headerName: "Source",
        valueGetter: ({ row }) => row.offer.userType,
      },
      {
        field: "asset.discount",
        flex: 1,
        headerName: "Discount/Last Valuation Round",
        renderCell: DiscountCellRenderer,
      },
      {
        field: "offer.shareClass",
        flex: 1,
        headerName: "Share Class",
        valueGetter: ({ row }) => row.offer.shareClass.join(" + "),
      },
      {
        field: "offer.creationDate",
        flex: 1,
        headerName: "Submitted",
        renderCell: CreationDateCellRenderer,
      },
      {
        field: "offer.id",
        width: 120,
        headerName: "",
        sortable: false,
        renderCell: ActionsCellRenderer,
        
      },
    ],
    []
  );
};
