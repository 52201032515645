import { UserTypeEnum } from "./user";

export enum OfferStatusEnum {
  denied = "denied",
  approved = "approved",
  waitingForApproval = "waitingForApproval",
}

export enum OfferTypeEnum {
  ask = "Ask",
  bid = "Bid",
}

export enum StructureTypeEnum {
  spv = "SPV",
  other = "Other",
  direct = "Direct",
  fowardContact = "Foward Contact",
  fundCommitment = "Fund Commitment",
}

export enum ShareClassEnum {
  common = "Common",
  preferedA = "Prefered A",
  preferedB = "Prefered B",
  preferedC = "Prefered C",
  preferedD = "Prefered D",
  preferedE = "Prefered E",
}

export interface GetOffersByParamsRequestBody {
  statuses: OfferStatusEnum[];
}

export interface Offer {
  id: string;
  size: string;
  assetId: string;
  currency: string;
  buyerId?: string;
  sellerId?: string;
  valuation?: number;
  sharePrice?: number;
  type: OfferTypeEnum;
  creationDate?: number;
  relatedOfferId?: string;
  status: OfferStatusEnum;
  minShareAmount?: number;
  maxShareAmount?: number;
  userType?: UserTypeEnum;
  shareClass: ShareClassEnum[];
  structure: StructureTypeEnum;
}
