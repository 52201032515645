import { captureException as sentryCaptureException } from "@sentry/browser";

import { apiURLs } from "../constants";
import { httpService } from "./http.service";
import { ReactSetterFunction, UpdaterService } from "./updater.service";
import {
  IUserAsset,
  IGetUserAssetByParamRequestBody,
  ISetupUserAssetRequestBody,
} from "@simetria/models";

const userAssetCache: Map<string, IUserAsset> = new Map();
const isLoadingCache: Map<string, boolean> = new Map();

const userAssetUpdaterService = new UpdaterService<IUserAsset>(userAssetCache);
const isLoadingUpdaterService = new UpdaterService<boolean>(isLoadingCache);

const createUserAsset = async (newUserAsset: Partial<IUserAsset>) => {
  const createUserAssetResponse = await httpService.post<IUserAsset>(
    apiURLs.common.createUserAsset,
    newUserAsset
  );
  const createdUserAsset = createUserAssetResponse.data.data;

  userAssetCache.set(createdUserAsset.id, structuredClone(createdUserAsset));

  return createdUserAsset;
};

const getUserAssetById = async (
  userAssetId: string,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = true,
  getFromBackend: boolean = false
) => {
  let userAsset = userAssetCache.get(userAssetId);
  if (!userAsset || getFromBackend) {
    const response = await httpService.get<IUserAsset>(apiURLs.common.getUserAsset(userAssetId));
    userAsset = response.data.data;
    userAssetCache.set(userAsset.id, userAsset);
  }
  if (subscribe) {
    userAssetUpdaterService.subscribeForUpdates(userAssetId, subscribe, isSingle);
  }
  return userAsset;
};

const getUserAssetIsLoadingById = (
  userAssetId: string,
  subscribe?: ReactSetterFunction
): boolean => {
  let userAssetIsLoading = isLoadingCache.get(userAssetId);

  if (userAssetIsLoading === null || userAssetIsLoading === undefined) {
    isLoadingCache.set(userAssetId, false);
  }
  if (subscribe) {
    isLoadingUpdaterService.subscribeForUpdates(userAssetId, subscribe, true);
  }
  return Boolean(userAssetIsLoading);
};

const updateUserAssetIsLoadingById = (userAssetId: string, userAssetIsLoading: boolean) => {
  isLoadingCache.set(userAssetId, userAssetIsLoading);

  isLoadingUpdaterService.notifySubscribers([userAssetId]);
};

const getUserAssetByParams = async (
  body: IGetUserAssetByParamRequestBody,
  subscribe?: ReactSetterFunction,
  isSingle: boolean = false,
  getFromBackend = false
) => {
  let userAssetList: IUserAsset[] = [];
  const { userIds, companyNames, statuses } = body;

  if (!getFromBackend) {
    const isUserIdsExists = Boolean(userIds?.length);
    const isStatusesExists = Boolean(statuses?.length);
    const isCompanyNamesExists = Boolean(companyNames?.length);

    userAssetCache.forEach(userAsset => {
      const { userId, companyName, status } = userAsset;

      let isExists = isUserIdsExists || isCompanyNamesExists || isStatusesExists;

      if (isUserIdsExists && isExists) {
        isExists = Boolean(userIds?.includes(userId));
      }

      if (isCompanyNamesExists && isExists) {
        isExists = Boolean(companyNames?.includes(companyName));
      }

      if (isStatusesExists && isExists) {
        isExists = Boolean(statuses?.includes(status));
      }

      if (isExists) {
        userAssetList.push(structuredClone(userAsset));
      }
    });
  }

  if (!userAssetList.length) {
    const response = await httpService.post<IUserAsset[]>(
      apiURLs.common.getUserAssetByParams,
      body
    );
    userAssetList = response.data.data;
    userAssetList.forEach(userAsset => {
      userAssetCache.set(userAsset.id, structuredClone(userAsset));
    });
  }
  if (subscribe) {
    const userAssetIdsList = userAssetList.map(userAsset => userAsset.id);
    userAssetUpdaterService.subscribeForUpdates(["1"], subscribe, isSingle);
  }

  return userAssetList;
};

const updateUserAsset = async (newUserAsset: IUserAsset) => {
  let updatedUserAsset = userAssetCache.get(newUserAsset.id);
  try {
    updateUserAssetIsLoadingById(newUserAsset.id, true);

    const response = await httpService.put<IUserAsset>(
      apiURLs.common.updateUserAsset(newUserAsset.id),
      newUserAsset
    );

    updatedUserAsset = response.data.data;
    userAssetCache.set(updatedUserAsset.id, structuredClone(updatedUserAsset));

    userAssetUpdaterService.notifySubscribers([updatedUserAsset.id]);

    updateUserAssetIsLoadingById(newUserAsset.id, false);
  } catch (error) {
    updateUserAssetIsLoadingById(newUserAsset.id, false);
    sentryCaptureException(`[userAsset-service] updateUserAsset => error:${error}`);
  }

  return updatedUserAsset;
};

const updateCachedUserAssetUser = (userAssetId: string, userAsset: Partial<IUserAsset>) => {
  const cachedUserAsset = userAssetCache.get(userAssetId);
  if (cachedUserAsset) {
    const updatedUserAsset = { ...cachedUserAsset, ...userAsset };
    userAssetCache.set(userAssetId, updatedUserAsset);
    userAssetUpdaterService.notifySubscribers([userAssetId]);
  }
};

const deleteUserAsset = async (userAssetId: string) => {
  try {
    await httpService.delete(apiURLs.common.deleteUserAsset(userAssetId));
    const cachedUserAsset = userAssetCache.get(userAssetId);
    if (cachedUserAsset) {
      userAssetCache.delete(userAssetId);
    }
  } catch (error) {
    sentryCaptureException(`[userAsset-service] deleteUserAsset => error:${error}`);
  }
};

const setupUserAsset = async (body: ISetupUserAssetRequestBody) => {
  const setupUserAssetResponse = await httpService.post<IUserAsset>(
    apiURLs.common.setupUserAsset,
    body
  );
  const newUserAsset = setupUserAssetResponse.data.data;
  userAssetUpdaterService.notifySubscribers([newUserAsset.id]);
  return newUserAsset;
};

export const userAssetService = {
  setupUserAsset,
  createUserAsset,
  deleteUserAsset,
  updateUserAsset,
  getUserAssetById,
  getUserAssetByParams,
  updateCachedUserAssetUser,
  getUserAssetIsLoadingById,
  updateUserAssetIsLoadingById,
};
