import { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import { useDialog } from "../../hooks";
import { OfferTypeEnum } from "../../types";
import { useColumns } from "./hooks/use-columns";
import { createStyles } from "../../styles/utils";
import { DataGrid } from "../../components/DataGrid/DataGrid";
import { useOffersGridData } from "./hooks/use-offers-grid-data";
import { CreateOfferDialog } from "./dialogs/CreateOfferDialog/CreateOfferDialog";
import { GridSearch } from "../../components/DataGrid/components/GridSearch";

const useStyles = createStyles({
  conatainer: {
    height: "100%",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridWrapper: {
    paddingTop: 9,
    height: "100%",
  },
});

export const Marketplace = () => {
  const styles = useStyles();
  const [offerType, setOfferType] = useState(OfferTypeEnum.ask);
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useDialog();
  const columns = useColumns();
  const { fetchData, gridData, allAssets } = useOffersGridData();

  const onCreateOfferHandler = (offerType: OfferTypeEnum) => () => {
    openCreateDialog();
    setOfferType(offerType);
  };

  return (
    <Box sx={styles.conatainer}>
      <Box sx={styles.header}>
        <Typography variant="h3" display="inline">
          Marketplace
        </Typography>
        <Stack direction="row" spacing={4}>
          <Button color="error" onClick={onCreateOfferHandler(OfferTypeEnum.ask)}>
            Create Ask
          </Button>
          <Button color="success" onClick={onCreateOfferHandler(OfferTypeEnum.bid)}>
            Create Bid
          </Button>
        </Stack>
      </Box>

      <Box sx={styles.gridWrapper}>
        <DataGrid
          rows={gridData}
          columns={columns}
          getRowId={row => row.offer.id}
          gridActions={() => (
            <Box mb={2}>
              <GridSearch />
            </Box>
          )}
        />
      </Box>
      {isCreateDialogOpen && (
        <CreateOfferDialog
          offerType={offerType}
          fetchData={fetchData}
          open={isCreateDialogOpen}
          handleClose={closeCreateDialog}
          allAssets={allAssets}
        />
      )}
    </Box>
  );
};
