import * as React from "react";
import { useAuth } from "./use-auth";
import { UserContext } from "../context";
import { userService } from "../services/user.service";
import { UserRoleType, UserRoleStatus } from "@simetria/models";
import { userRoleService } from "../services/user-role.service";

const { useCallback, useState, useContext, useEffect } = React;

export const useAuthenticateUser = (authUserRole: UserRoleType) => {
  const { auth0Id, oauth0User, getAccessTokenSilently } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canAcceesApp, setCanAcceesApp] = useState<boolean | null>(null);
  const context = useContext(UserContext);
  const { setUser } = context;
  const authUserEmail = oauth0User?.email;

  const auhtenticateUser = useCallback(
    async (authId: string, authUserEmail: string) => {
      setIsLoading(true);
      let canAcceesApp = false;
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem("accessToken", accessToken);
        if (authId) {
          const getUserByAuthIdResponse = await userService.getUserByAuthId({
            authId,
            email: authUserEmail,
          });

          if (setUser) {
            setUser(getUserByAuthIdResponse);
          }

          const userId = getUserByAuthIdResponse.id;
          const getUserRoleByParamsResponse = await userRoleService.getUserRoleByParams({
            userId,
            status: UserRoleStatus.active,
          });

          const userRoles = getUserRoleByParamsResponse;
          const isShareholder = userRoles.some(userRole => userRole.type === authUserRole);
          if (isShareholder) {
            canAcceesApp = true;
          }
        }
      } catch (error) {
        canAcceesApp = false;
      }
      setIsLoading(false);
      if (canAcceesApp) {
        setCanAcceesApp(canAcceesApp);
      }
    },
    [setUser, authUserRole, getAccessTokenSilently]
  );

  useEffect(() => {
    if (auth0Id && authUserEmail) {
      auhtenticateUser(auth0Id, authUserEmail);
    }
  }, [auth0Id, authUserEmail, auhtenticateUser]);

  return { canAcceesApp, isUserAccessLoading: isLoading };
};
