import { Box, Link } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { OffersGridRow } from "../../../pages/Marketplace/types";

export const CompanyNameCellRenderer = ({ row }: GridRenderCellParams<OffersGridRow>) => {
  const companyName = row.asset?.name || "";
  const dataUrl = row.asset?.assetDataUrl || "";

  return (
    <Stack spacing={4} direction="row" alignItems="center">
      <Box component="img" src={row.asset?.logoSrc} sx={{ height: "40px" }} />
      {dataUrl ? (
        <Link href={dataUrl} target="_blank" rel="noreferrer" >{companyName}</Link>
      ) : (
        <Typography variant="body6">{companyName}</Typography>
      )}
    </Stack>
  );
};
