import { useAuth } from "@simetria/common/src/hooks";

import { ReactComponent as WhiteLogo } from "@simetria/common/src/assets/icons/logo-white.svg";
import { Box, Button, Link, Typography } from "@mui/material";
import { createStyles } from "../../styles/utils";

import SigninBackground from "@simetria/common/src/assets/icons/sign-cover-image.svg";
import { primaryP10, primaryP50 } from "../../styles/theme/colors";

const useStyles = createStyles(({ palette }) => ({
  signInWrapper: {
    display: "grid",
    gridTemplateRows: "2fr repeat(3, 1fr)",
    gap: "0px 0px",
    gridTemplateAreas: `
    '.'
    'logo'
    'button'
    'footer'
    `,
    justifyItems: "center",

    width: "100%",
    height: "100%",
    backgroundColor: primaryP50,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url(${SigninBackground})`,
  },

  logo: {
    gridArea: "logo",
  },

  signInButtonWrapper: {
    width: "400px",
    justifyContent: "center",
    gridArea: "button",
  },

  signInButton: {
    color: palette.primary.main,
    fontFamily: "PoppinsRegular",
    backgroundColor: palette.common.white,
    fontSize: "28px",
    lineHeight: "42px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryP10,
    },
  },

  infoText: {
    gridArea: "text",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: 3,

    color: palette.common.white,
  },

  footer: {
    gridArea: "footer",
    alignSelf: "flex-end",

    paddingBottom: 9,
  },
}));

export const SignInPage = (): JSX.Element => {
  const { loginWithPopup } = useAuth();
  const styles = useStyles();
  return (
    <Box sx={styles.signInWrapper}>
      <Box sx={styles.logo}>
        <WhiteLogo />
      </Box>

      <Box sx={styles.signInButtonWrapper}>
        <Button sx={styles.signInButton} fullWidth size="large" onClick={() => loginWithPopup()}>
          Sign In
        </Button>
      </Box>

      <Box sx={styles.footer}>
        <Typography display="block" sx={styles.infoText}>
          Please check the sign in details sent to you by E-mail
        </Typography>

        <Typography sx={styles.infoText}>
          <Link
            color="inherit"
            target="_blank"
            rel="noopener noreferrer"
            href="https://simetria.zendesk.com/hc/en-us/articles/5642868232349-Terms-of-Use"
          >
            Terms of Service
          </Link>

          <Link
            color="inherit"
            href="https://simetria.zendesk.com/hc/en-us/articles/5641978918429-Privacy-Policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
