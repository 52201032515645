import { Components, Theme } from "@mui/material";

import { green60, primaryP10, primaryP80, red60 } from "../colors";

export const MuiButton: Components<Theme>["MuiButton"] = {
  styleOverrides: {
    root: {
      fontSize: "14px",
      textTransform: "none",
      fontFamily: "PoppinsMedium",
    },
    sizeSmall: {
      height: "28px",
    },
    sizeMedium: {
      height: "36px",
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: primaryP80,
      },
    },
    containedSuccess: {
      "&:hover": {
        backgroundColor: green60,
      },
    },
    containedError: {
      "&:hover": {
        backgroundColor: red60,
      },
    },
    outlinedPrimary: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: primaryP10,
      },
    },
  },
  defaultProps: {
    variant: "contained",
    size: "small",
  },
};
