import { isPrimitive } from "../utils/is-primitive.util";

export type ReactSetterFunction = React.Dispatch<React.SetStateAction<any>>;

interface SettersValue {
  isSingle: boolean;
  settersRef: Set<ReactSetterFunction>;
  filterBy?: Record<string, any>;
}

export class UpdaterService<T> {
  private idsToSetterMap: Map<string, SettersValue> = new Map();

  constructor(private cache: Map<string, T>) {}

  subscribeForUpdates(
    id: string | string[],
    subscribeFunction: ReactSetterFunction,
    isSingle: boolean,
    filterBy?: Record<string, any>
  ) {
    const key = createKey(id);
    const settersValue: SettersValue | undefined = this.idsToSetterMap.get(key);

    if (settersValue) {
      const prevSetters = settersValue.settersRef;
      prevSetters.add(subscribeFunction);
    } else {
      const setters = new Set<ReactSetterFunction>();
      setters.add(subscribeFunction);

      const settersValue: SettersValue = { isSingle, settersRef: setters, filterBy };
      this.idsToSetterMap.set(key, settersValue);
    }
  }

  notifySubscribers(updatedEntityIds: string[]) {
    updatedEntityIds.forEach(updatedEntityId => {
      const settersValue = this.idsToSetterMap.get(updatedEntityId);
      if (settersValue) {
        const { isSingle, settersRef } = settersValue;
        if (!isSingle) {
          return;
        }
        const entity = this.cache.get(updatedEntityId);
        if (entity !== null && entity !== undefined) {
          settersRef.forEach(setState => setState(structuredClone(entity)));
        }
      }
    });

    // notify multiple, with all of the entities in the cache
    const entities: any[] = [];
    this.cache.forEach(entity => entities.push(structuredClone(entity)));
    this.idsToSetterMap.forEach(({ settersRef, isSingle, filterBy }) => {
      if (isSingle) {
        return;
      }

      const entitiesToNotify = entities.filter(entity => applyFilter(filterBy, entity));
      settersRef.forEach(setState => setState(entitiesToNotify));
    });
  }
}

const createKey = (ids: string[] | string) => {
  const key = typeof ids === "string" ? ids : ids.sort().join(",");
  return key;
};

const applyFilter = (filterBy: any, obj: any) => {
  if (!filterBy) {
    // if theres no filter then it's OK
    return true;
  }
  let hasValues = false;
  const filterEntries = Object.entries(filterBy);
  for (const [filterKey, filterValue] of filterEntries) {
    if (isPrimitive(filterValue)) {
      hasValues = obj[filterKey] === filterValue;
    } else if (Array.isArray(filterValue)) {
      hasValues = filterValue.every(val => obj[filterKey].includes(val));
    }
    if (!hasValues) {
      return false;
    }
  }
  return hasValues;
};
